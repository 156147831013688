import React from 'react'
import { ContentGrid, IconType } from '@fjordkraft/fjordkraft.component.library'
import { getPageContent } from './SpotPricePageData'
import { AreaChartCard, DefaultColumnChartColors, IAreaChartCard } from '../../components'
import { tNumber, getText } from '../../services'
import { Constants } from '../../data'
import { IDefaultViewProps, PageV2 } from '../PageV2'
import './SpotPricePage.scss'
import { CommercialBlock, PlankWall } from '../../blocks'
import classNames from 'classnames'
import { getPlankPrefab } from '../../Prefabs'
import { ToggleButton } from '../../components/ToggleButton/ToggleButton'
import { format } from 'date-fns'
import { ITransaction } from '../BenefitsPage/TransactionsPage/TransactionsPageData'

export interface ISpotPricePage extends IDefaultViewProps {
  chartData: IAreaChartCard
  stats: any
}

export const SpotPricePage = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'spot-price-page'

  const [includeSubsidy, setIncludeSubsidy] = React.useState<boolean>(false)
  const [selectedDay, setSelectedDay] = React.useState<string>('today')

  const getToolTipHours = (chartData: IAreaChartCard, dataPointIndex: any) => {
    if (dataPointIndex === 23) return '23:00 - 00:00'
    return `${chartData.categories?.[dataPointIndex]} - ${chartData.categories?.[dataPointIndex + 1]}`
  }

  const priceToText = (price: number, translations: any) => {
    return `<span class='price'>${tNumber(price, 'no-NO', 2)} ${getText('currency', translations)}</span>`
  }

  const getToolTipPrice = (
    includeSubsidy: boolean,
    series: any,
    seriesIndex: any,
    dataPointIndex: any,
    translations: any
  ) => {
    let price = priceToText(series[seriesIndex][dataPointIndex], translations)
    if (
      includeSubsidy &&
      (seriesIndex > 1 || series[seriesIndex][dataPointIndex] === series[seriesIndex + 2][dataPointIndex])
    ) {
      let priceInclSubsidy = series[seriesIndex][dataPointIndex]
      let priceWithoutSubsidy = seriesIndex > 1 ? series[seriesIndex - 2][dataPointIndex] : priceInclSubsidy
      let priceWithoutSubsidyText = priceToText(priceWithoutSubsidy, translations)
      let priceInclSubsidyText = priceToText(priceInclSubsidy, translations)
      price = `${getText('subsidyIncluded', translations)}: ${priceInclSubsidyText}<br><br>${getText('subsidyExcluded', translations)}: ${priceWithoutSubsidyText}`
    }
    return price
  }

  const isSubsidy = (includeSubsidy: boolean, series: any, seriesIndex: any, dataPointIndex: any) => {
    let subsidy = false
    if (
      includeSubsidy &&
      (seriesIndex > 1 || series[seriesIndex][dataPointIndex] === series[seriesIndex + 2][dataPointIndex])
    ) {
      subsidy = true
    }
    return subsidy
  }

  // ************************************
  // Render
  // ************************************

  const controls = (translations: ITransaction) => (
    <div className={classNames('controls')}>
      <ToggleButton
        defaultValue='today'
        elements={[
          {
            text: getText('today', translations),
            value: 'today'
          },
          {
            text: getText('tomorrow', translations),
            value: 'tomorrow'
          }
        ]}
        onChange={value => {
          if (value !== selectedDay) {
            setSelectedDay(value)
          }
        }}
      />
    </div>
  )

  const subHeader = (config: ISpotPricePage) => {
    const { translations, chartData } = config
    const date = new Date()
    let priceNow = `${getText('priceNow', translations)}: `
    let currencyLong = ` ${getText('currencyLong', translations)}`
    let subsidy = includeSubsidy
      ? `(${getText('subsidyIncluded', translations)})`
      : `(${getText('subsidyExcluded', translations)})`

    if (selectedDay === 'tomorrow') {
      date.setDate(date.getDate() + 1)
      priceNow = ' '
      currencyLong = ' '
      subsidy = ' '
    }
    const formattedDate = format(date, 'dd.MM.yyyy')
    return (
      <div className={classNames('subHeader')}>
        <div className={classNames('currentPrice')}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <span className={classNames('h4')}>{priceNow}</span>
            <span className={classNames('h2', 'h2-bold')}>
              {chartData.currentValue ? `${tNumber(chartData.currentValue, 'no-NO', 2)}` : ' '}
            </span>
            <span className={classNames('h4')}>{currencyLong}</span>
          </div>
          <div className={classNames('h4')}>{subsidy}</div>
        </div>
        <span className={classNames('h4')}>{formattedDate}</span>
      </div>
    )
  }

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.spotPrice.type,
      usesSubPage: true
    },
    dependencies: [{ includeSubsidy, selectedDay }],
    handleData: getPageContent,
    render: (config: ISpotPricePage) => {
      const { chartData, translations, activeBrand, activeTheme, stats } = config

      return (
        <ContentGrid
          className={`${classPrefix}`}
          direction='column'
          alignment='top-center'
          tagType='section'
          gap={3}
        >
          <AreaChartCard
            {...chartData}
            cardSubHeader={subHeader(config)}
            controls={controls(translations)}
            chartTooltip={{
              show: true,
              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                let x: string = ''
                let y: string = ''
                let tooltipString = ''

                if (chartData.categories) {
                  x = getToolTipHours(chartData, dataPointIndex)
                  y = getToolTipPrice(includeSubsidy, series, seriesIndex, dataPointIndex, translations)
                }

                if (!isNaN(series[seriesIndex][dataPointIndex])) {
                  tooltipString = `<h4>${x}</h4><label>${y}</label>`
                } else {
                  tooltipString = `<h4>${x}</h4>`
                }

                return (
                  `<div class='tooltip tooltip_${isSubsidy(includeSubsidy, series, seriesIndex, dataPointIndex) ? 'subsidy' : 'price'}'>` +
                  tooltipString +
                  '</div>'
                )
              },
              x: {
                formatter: (value: any) => {
                  let adjVal = value - 1
                  return adjVal <= 9 ? `0${adjVal}:00` : `${adjVal}:00`
                }
              },
              y: {
                formatter: (value: any) => {
                  return `${tNumber(value, 'no-NO', 2)} ${getText('currency', translations)}`
                },
                suffix: ` ${getText('currency', translations)}`,
                showOnAxisLabels: true
              }
            }}
            className={classNames(`${classPrefix}__chart`, { ['includeSubsidy']: includeSubsidy })}
            theme={activeTheme}
            brand={activeBrand}
          >
            <div className={`${classPrefix}__subsidy`}>
              <div>
                <div>{getText('highest', translations)}</div>
                <div>{stats.max.hour}</div>
                <div>{stats.max.value}</div>
              </div>
              <div>
                <div>{getText('lowest', translations)}</div>
                <div>{stats.min.hour}</div>
                <div>{stats.min.value}</div>
              </div>
              <div>
                <div>{getText('average', translations)}</div>
                <div></div>
                <div>{stats.average}</div>
              </div>
            </div>
          </AreaChartCard>
          <PlankWall
            planks={[
              getPlankPrefab('Toggle', {
                title: getText('plankToggleSupportTitle', translations),
                left: {
                  icon: IconType.Square,
                  customization: {
                    icon: {
                      type: IconType.Square,
                      color: DefaultColumnChartColors[1]
                    }
                  }
                },
                description: getText('plankToggleSupportDesc', translations),
                active: includeSubsidy,
                onChange(state) {
                  setIncludeSubsidy(state)
                }
              })
            ]}
          />
          <CommercialBlock />
        </ContentGrid>
      )
    }
  })
}
