import { IComponent, StyleGrid, TextInput } from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import React from 'react'
import { getText } from '../../../../services'
import { CustomerEditorInputTemplate } from './CustomerEditorInputTemplate'
import './CustomerEditorInput.scss'

export type InfoInputType =
  | 'email'
  | 'name'
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'postalLocation'
  | 'postalCode'
  | 'birthday'
  | 'customerNumber'
  | 'address'
  | 'residenceName'

export interface ICustomerEditorInput extends IComponent {
  valid: any
  type: InfoInputType
  translations: any
  value: string
  size?: 'full' | 'half' | 'small' | 'large'
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const CustomerEditorInput = (props: ICustomerEditorInput) => {
  // ************************************
  // Properties
  // ************************************

  const { theme, brand, valid, type, translations, value, size = 'full', onChange } = props
  const classPrefix = 'customer-editor-input'

  // ************************************
  // Helpers
  // ************************************

  const _getInputType = (type: InfoInputType) => {
    switch (type) {
      case 'name':
      case 'firstName':
      case 'lastName':
      case 'address':
      case 'postalLocation':
      case 'residenceName':
        return 'text'
      case 'customerNumber':
      case 'postalCode':
        return 'number'
      case 'email':
        return 'email'
      case 'phoneNumber':
        return 'tel'
    }
  }

  // ************************************
  // Render
  // ************************************
  return (
    <StyleGrid
      direction={'column'}
      alignment={'center-left'}
      className={classNames(`${classPrefix}`, {
        [`${classPrefix}--${size}`]: size
      })}
    >
      <TextInput
        className={classNames(`${classPrefix}`, {
          [`${classPrefix}--${size}`]: size
        })}
        template={CustomerEditorInputTemplate(theme)}
        theme={theme}
        brand={brand}
        title={getText(`input${type}`, translations)}
        invalidated={value.length >= 1 && !valid(value).isValid}
        invalidatedText={getText(`inputInvalid${type}`, translations)}
        type={_getInputType(type) ?? 'text'}
        value={value}
        onChange={onChange}
      />
    </StyleGrid>
  )
}