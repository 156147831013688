import { useContext, createContext } from 'react'
import { ICustomerAccountInformation, IDefaultProps, PageType } from '../../models'
import { Constants } from '../../data'

export interface IApplicationDefaultSetup {
  page: PageType
  subPage?: string
}

interface IApplicationDefaultContext {
  defaultProps: IDefaultProps
  activeAccount?: ICustomerAccountInformation
  setActiveAccount: (data?: ICustomerAccountInformation) => void
}

export const ApplicationDefaultContext = createContext<IApplicationDefaultContext>({
  defaultProps: {
    activeBrand: Constants.uiBrand,
    activeTheme: Constants.uiTheme
  } as any,
  activeAccount: undefined,
  setActiveAccount: () => {}
})

export const useApplicationDefaultContext = () => useContext(ApplicationDefaultContext)