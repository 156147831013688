import React, { useEffect, useState } from 'react'
import {
  ContentGrid,
  getCounterTheme,
  IAction,
  IComponent,
  StyleGrid,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import { getRequest } from '../../../contexts'
import { Card } from '../Card'
import classnames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import { ICustomer, ICustomerAccountInformation, ICustomerInvoice, IGridInvoice, IInvoiceDemand } from '../../../models'
import {
  createString,
  getCorrectInvoicePeriodDate,
  getText,
  tNumber,
  translateInvoiceCardTitle,
  translateInvoiceStatus
} from '../../../services'
import { ActionButton, BareAccordion, InvoicePdfButton, MSTooltip } from '../../../components'
import { format, getDaysInMonth } from 'date-fns'
import { InvoiceCardTemplate } from './InvoiceCardTemplate'
import { h4TextPrefab, MS_ButtonTemplate, paragraphTextPrefab } from '../../../Prefabs'
import { INotificationNote, NotificationBlock } from '../../../blocks/NotificationBlock/NotificationBlock'
import { nb } from 'date-fns/locale'
import { useLocation } from 'react-router'
import './InvoiceCard.scss'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'

export interface IInvoiceCard extends IComponent {
  invoiceAction?: IAction | null
  electricityInvoiceAction?: IAction | null
  gridRentInvoiceAction?: IAction[] | null
  invoice: ICustomerInvoice
  simplified?: boolean
  notifications?: INotificationNote[]
  onDelay?: (invoice: ICustomerInvoice) => void
  demands?: IInvoiceDemand[]
  desktopView?: boolean
  GET: getRequest
  translations: any
}

/**
 *   The InvoiceCard (MinSide) is used to display invoice details
 *   @type {string} price (required)
 *   @type {string} gridRent (optional)
 *   @type {number} kidNumber (required)
 *   @type {string} accountNumber (required)
 *   @type {number} invoiceNumber (required)
 *   @type {string} biller (required)
 *   @type {IAction} invoiceAction (optional)
 *   @type {IAction} electricityInvoiceAction (optional)
 *   @type {IAction} gridRentInvoiceAction (optional)
 *   @type {ICustomerInvoice} invoice
 *   @type {boolean} simplified
 *   @type {INotificationNote[]} notifications (optional)
 *   @type {function} onDelay (optional)
 */
export const InvoiceCard = (props: IInvoiceCard) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    brand,
    theme,
    invoiceAction,
    electricityInvoiceAction,
    gridRentInvoiceAction,
    invoice,
    notifications,
    onDelay,
    demands,
    desktopView = true,
    GET,
    translations
  } = props

  const { pathname } = useLocation()
  const { isGuest } = useApplicationGuestsAndHostsContext()
  const classPrefix = 'ms-invoice-card'

  useEffect(() => {
    _handleDemand()
  }, [])

  // ************************************
  // Lifecycle
  // ************************************

  const [demandNotifications, setDemandNotifications] = useState<INotificationNote[]>()
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string>()
  const [hasAutomaticPayment, setAutomaticPayment] = useState<boolean>(false)
  const inPast = pathname.split('/')[2] === 'historikk'

  // ************************************
  // Helper Functionality
  // ************************************

  const _canRenderBottomContent = () => {
    if (invoiceAction || electricityInvoiceAction || (onDelay && invoice.delayable)) {
      return true
    }

    return false
  }

  const _getCorrectPeriodDateData = () => {
    let date: Date = getCorrectInvoicePeriodDate(invoice.invoicePeriod)
    let month: string = format(date, 'MMMM', { locale: nb })
    let typeAndMonth: string = `${translateInvoiceCardTitle(invoice.type ?? 'None', translations)} ${month}`
    let fromToDate: string = `01.${format(date, 'MM')} - ${getDaysInMonth(date)}.${format(date, 'MM')}`

    return { month, fromToDate, typeAndMonth }
  }

  const _getGridRent = () => {
    let amount: number = 0

    invoice.gridInvoices?.forEach((gridInvoice: IGridInvoice) => {
      amount += gridInvoice.amountExclusiveOfVat + gridInvoice.vatAmount
    })

    return {
      amount,
      NOK: `${tNumber(amount, 'no-NO')} ${getText('currency', translations)}`
    }
  }

  const _getGeneralCost = () => {
    let amount: number = _getTotalCost().amount - _getGridRent().amount

    return {
      amount,
      NOK: `${tNumber(amount, 'no-NO')} ${getText('currency', translations)}`
    }
  }

  const _getTotalCost = () => {
    return {
      NOK: tNumber(invoice.totalAmount ?? 0, 'no-NO'),
      amount: invoice.totalAmount ?? 0
    }
  }

  const _getTotalCostStatus = () => {
    switch (invoice.paymentStatus) {
      case 'Overdue':
        return createString(getText('stillunpaid', translations), {
          date: format(new Date(invoice.dueDate), 'dd.MM.yyyy')
        })
      case 'Credit':
      case 'Paid':
        return format(new Date(invoice.dueDate), 'dd.MM.yyyy')
      default:
        return createString(getText('unpaid', translations), {
          date: format(new Date(invoice.dueDate), 'dd.MM.yyyy')
        })
    }
  }

  const _getDeliveryMethod = () => {
    switch (invoice.deliveryMethod) {
      case 'EINVOICE2':
        return getText('selectionEInvoiceTitle', translations)
      case 'Paper':
        return getText('selectionPaperTitle', translations)
      case 'SMS_PDF':
        return getText('selectionEmailAndSMSTitle', translations)
      case 'PDF':
        return getText('selectionEmailTitle', translations)
      default:
        return ''
    }
  }

  const _hasSteddi = (accountId: string, userData?: ICustomer) => {
    if (userData) {
      return userData?.accounts.find(
        (e: ICustomerAccountInformation) => e.steddiInfo?.active && `${e.steddiInfo.beasAccountId}` === accountId
      )
    } else {
      return false
    }
  }

  const _fetchPaymentMethod = async () => {
    let text: string = ''

    if (invoice.userData) {
      let hasDirectDebit = await GET(`Account/${invoice.electricityAccountNumber}/avtaleGiro/status`)
      let hasSteddi = _hasSteddi(invoice.electricityAccountNumber, invoice.userData)

      if (hasDirectDebit?.data === 'Active') {
        text = getText('directDebit', translations)
      } else if (hasSteddi) {
        text = getText('steddi', translations)
      } else {
        text = getText('regularPaymentMethod', translations)
      }

      if ((hasDirectDebit?.data === 'Active' || hasSteddi) && !inPast) {
        setAutomaticPayment(true)
      }

      setCurrentPaymentMethod(text)
    }
  }

  const _hasAutomaticPayment = (): boolean => {
    if (hasAutomaticPayment) {
      return true
    } else {
      return false
    }
  }

  // ************************************
  // Demand handling
  // ************************************

  const _handleDemand = () => {
    if (demands && demands.length > 0 && invoice.token && !demandNotifications) {
      let demandChildren = (
        <StyleGrid
          alignment={'center-left'}
          direction={'column'}
          gap={1}
        >
          {demands?.map(demand => {
            return (
              <InvoicePdfButton
                key={uuid4()}
                text={`${demand.documentTitle} ${format(new Date(demand.documentStoredDate), 'dd.MM.yyyy')}`}
                token={invoice.token ?? ''}
                url={demand.pdfPath}
              />
            )
          })}
        </StyleGrid>
      )

      setDemandNotifications([
        {
          type: 'Error',
          title: createString(getText('demandNotificationTitle', translations), {
            amount: demands.length
          }),
          children: demandChildren
        }
      ])
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderTopSection = () => {
    return (
      <StyleGrid
        direction='row'
        alignment='top-left'
        spaceBetween={true}
        className={`${classPrefix}__top`}
      >
        <ContentGrid
          direction='column'
          alignment='top-left'
          tagType='section'
          spaceBetween={true}
          className={`${classPrefix}__top__left`}
          gap={1}
        >
          <Text
            {...h4TextPrefab()}
            theme={getCounterTheme(theme)}
          >
            {_getCorrectPeriodDateData().typeAndMonth}
          </Text>
          <Text
            {...paragraphTextPrefab()}
            theme={getCounterTheme(theme)}
            faded={true}
          >
            {_getCorrectPeriodDateData().fromToDate}
          </Text>
        </ContentGrid>
        <ContentGrid
          direction='column'
          alignment='top-right'
          tagType='section'
          className={`${classPrefix}__top__left`}
          gap={1}
        >
          <Text
            {...h4TextPrefab()}
            theme={getCounterTheme(theme)}
          >
            {getText('plankInvoiceAgreement', translations)}
          </Text>
          <Text
            {...paragraphTextPrefab()}
            theme={getCounterTheme(theme)}
            faded
          >
            {invoice.electricityAccountNumber}
          </Text>
        </ContentGrid>
      </StyleGrid>
    )
  }

  const _renderCostSection = () => {
    return (
      <StyleGrid
        direction='column'
        alignment='top-left'
        boxSizing='border-box'
        className={`${classPrefix}__cost`}
        gap={2}
      >
        <StyleGrid
          direction='row'
          alignment='center-left'
          boxSizing='border-box'
          className={`${classPrefix}__cost`}
          spaceBetween
        >
          <ContentGrid
            direction='column'
            alignment='center-left'
            boxSizing='border-box'
            gap={3}
            gapType='px'
            tagType='section'
          >
            <ContentGrid
              direction='row'
              alignment='bottom-left'
              gap={1}
              tagType={'section'}
            >
              <Text
                {...paragraphTextPrefab()}
                weight={700}
                size='extra-large'
                className={`${classPrefix}__cost__price`}
                brand={brand}
                theme={getCounterTheme(theme)}
              >
                {_getTotalCost().NOK}
              </Text>
              <Text
                {...paragraphTextPrefab()}
                brand={brand}
                weight={700}
                size={'medium'}
                theme={getCounterTheme(theme)}
              >
                {getText('currency', translations)}
              </Text>
            </ContentGrid>
            <Text
              {...paragraphTextPrefab()}
              brand={brand}
              theme={getCounterTheme(theme)}
            >
              {_getTotalCostStatus()}
            </Text>
          </ContentGrid>
          <ContentGrid
            direction='column'
            alignment='center-left'
            boxSizing='border-box'
            gap={3}
            gapType='px'
            tagType='section'
          >
            <Text
              {...paragraphTextPrefab()}
              color={
                translateInvoiceStatus({
                  invoice,
                  translation: translations,
                  useDate: false,
                  usesAutoPayment: _hasAutomaticPayment()
                }).color
              }
              theme={getCounterTheme(theme)}
              faded={invoice.paymentStatus === 'Unpaid'}
            >
              {
                translateInvoiceStatus({
                  invoice,
                  translation: translations,
                  useDate: false,
                  usesAutoPayment: _hasAutomaticPayment()
                }).text
              }
            </Text>
          </ContentGrid>
        </StyleGrid>
        {currentPaymentMethod !== 'Steddi' && (
          <StyleGrid
            direction='row'
            alignment='center-left'
            boxSizing='border-box'
            className={`${classPrefix}__cost`}
            spaceBetween
          >
            <ContentGrid
              direction='column'
              alignment='bottom-left'
              boxSizing='border-box'
              gap={3}
              gapType='px'
              tagType='section'
            >
              <Text
                {...paragraphTextPrefab()}
                theme={getCounterTheme(theme)}
                weight={600}
              >
                {getText('includedInInvoice', translations)}
              </Text>

              <Text
                {...paragraphTextPrefab()}
                theme={getCounterTheme(theme)}
                faded={invoice.paymentStatus === 'Unpaid'}
              >
                {getText('electricityCost', translations)}
              </Text>
              <Text
                {...paragraphTextPrefab()}
                theme={getCounterTheme(theme)}
                faded={invoice.paymentStatus === 'Unpaid'}
              >
                {getText('gridRent', translations)}
              </Text>
            </ContentGrid>
            <ContentGrid
              direction='column'
              alignment='bottom-right'
              boxSizing='border-box'
              gap={3}
              gapType='px'
              tagType='section'
            >
              <Text
                {...paragraphTextPrefab()}
                theme={getCounterTheme(theme)}
                weight={600}
              >
                {_getGeneralCost().NOK}
              </Text>
              <Text
                {...paragraphTextPrefab()}
                theme={getCounterTheme(theme)}
                weight={600}
              >
                {_getGridRent().NOK}
              </Text>
            </ContentGrid>
          </StyleGrid>
        )}
      </StyleGrid>
    )
  }

  const _renderBottomNavigation = () => {
    if (_canRenderBottomContent()) {
      return (
        <StyleGrid
          className={`${classPrefix}__bottom`}
          direction='column'
          alignment='top-left'
        >
          {onDelay && invoice.delayable && (
            <ContentGrid
              direction='row'
              alignment={desktopView ? 'center-left' : 'center'}
              className={`${classPrefix}__bottom__navigation`}
              tagType={'nav'}
              gap={1}
            >
              <ActionButton
                template={MS_ButtonTemplate(theme ?? 'Light', 'secondary')}
                key={uuid4()}
                className={classnames('', {
                  [`${classPrefix}__action-button`]: !desktopView
                })}
                padding='small'
                brand={brand}
                action={{
                  onClick: () => {
                    onDelay(invoice)
                  },
                  disabled: isGuest,
                  text: getText('delay', translations)
                }}
              />
            </ContentGrid>
          )}
          <StyleGrid
            className={`${classPrefix}__bottom__navigation`}
            direction='column'
            alignment='top-left'
            brand={brand}
            gap={2}
          >
            {_renderInvoicePdfLinks()}
          </StyleGrid>
        </StyleGrid>
      )
    }
  }

  const _renderInvoicePdfLinks = () => {
    return (
      <ContentGrid
        direction='column'
        alignment='center-left'
        className={`${classPrefix}__top__right`}
        gap={1}
        tagType={'nav'}
      >
        {invoiceAction && invoice.token && (
          <InvoicePdfButton
            type={'clean'}
            theme={theme}
            brand={brand}
            url={invoiceAction.link ?? ''}
            text={invoiceAction.text ?? ''}
            token={invoice.token}
            invoice={invoice}
          />
        )}
        {electricityInvoiceAction && invoice.token && (
          <InvoicePdfButton
            type={'clean'}
            theme={theme}
            brand={brand}
            url={electricityInvoiceAction.link ?? ''}
            text={electricityInvoiceAction.text ?? ''}
            token={invoice.token}
            invoice={invoice}
          />
        )}
        {gridRentInvoiceAction && invoice.token && _renderGridRentPdfs()}
      </ContentGrid>
    )
  }

  const _renderText = (
    text: string,
    bold: boolean = false,
    align: 'align-left' | 'align-center' | 'align-right' = 'align-left'
  ) => {
    return (
      <Text
        {...paragraphTextPrefab()}
        className={`${classPrefix}__text`}
        weight={bold ? 700 : 400}
        size={desktopView ? 'regular' : 'small'}
        brand={brand}
        theme={getCounterTheme(theme)}
        align={align}
        wrap='pre'
      >
        {text}
      </Text>
    )
  }

  const _renderInvoiceDetailRow = (leftText: string, rightText: string, copyable: boolean = false) => {
    return (
      <ContentGrid
        className={`${classPrefix}__details-row`}
        direction='row'
        alignment='top-left'
        spaceBetween
        boxSizing='border-box'
        tagType='section'
      >
        {_renderText(leftText)}
        {copyable && (
          <MSTooltip
            theme={getCounterTheme(theme)}
            brand={brand}
            alignment='top-center'
            label={rightText}
            onClick={() => {
              navigator.clipboard.writeText(rightText)
            }}
            text={createString(getText('copiedText', translations), {
              value: rightText
            })}
          />
        )}
        {!copyable && _renderText(rightText, true, 'align-right')}
      </ContentGrid>
    )
  }

  const _renderInvoiceSettings = () => {
    _fetchPaymentMethod()

    return (
      <StyleGrid
        direction='row'
        alignment='center'
        className={`${classPrefix}__middle`}
        spaceBetween={true}
        boxSizing='border-box'
        gap={2}
      >
        {currentPaymentMethod &&
          !inPast &&
          _renderInvoiceDetailRow(getText('paymentMethod', translations), currentPaymentMethod)}
        {invoice.deliveryMethod &&
          _renderInvoiceDetailRow(getText('deliveryMethod', translations), _getDeliveryMethod())}
      </StyleGrid>
    )
  }

  const _renderHasAutomaticPayment = () => {
    if (hasAutomaticPayment) {
      return (
        <BareAccordion
          textOpen={getText('showLessInvoiceDetails', translations)}
          textClosed={getText('showMoreInvoiceDetails', translations)}
        >
          {_renderInvoiceDetails()}
        </BareAccordion>
      )
    }
  }

  const _renderDivider = () => {
    return <span className={`${classPrefix}__divider`} />
  }

  const _renderInvoiceDetails = () => {
    return (
      <StyleGrid
        direction='row'
        alignment='center'
        className={`${classPrefix}__middle`}
        spaceBetween={true}
        boxSizing='border-box'
        gap={2}
      >
        {_renderInvoiceDetailRow(getText('kid', translations), `${invoice.kid}`, true)}
        {_renderInvoiceDetailRow(getText('accountNumber', translations), `${invoice.payToAccountNumber}`, true)}
        {_renderInvoiceDetailRow(getText('invoiceNumber', translations), `${invoice.invoiceNumber}`)}
        {_renderInvoiceDetailRow(getText('sender', translations), `${invoice.sender}`)}
        {_renderInvoiceDetailRow(
          getText('installation', translations),
          `${invoice.installations?.map(installation => installation.streetAddress).join('\n')}`
        )}
      </StyleGrid>
    )
  }

  const _renderGridRentPdfs = () => {
    if (gridRentInvoiceAction && invoice.token) {
      return gridRentInvoiceAction.map((action: IAction) => {
        return (
          <InvoicePdfButton
            key={uuid4()}
            theme={theme}
            brand={brand}
            type={'clean'}
            url={action.link ?? ''}
            text={action.text ?? ''}
            token={invoice.token ?? ''}
            invoice={invoice}
          />
        )
      })
    }
  }

  // ************************************
  // Render
  // ************************************

  return (
    <Card
      id={id}
      brand={brand}
      theme={theme}
      className={classPrefix}
      template={InvoiceCardTemplate(theme)}
    >
      {demandNotifications && (
        <NotificationBlock
          className={`${classPrefix}__notifications`}
          notifications={demandNotifications}
        />
      )}
      {notifications && notifications.length > 0 && (
        <NotificationBlock
          className={`${classPrefix}__notifications`}
          notifications={notifications}
        />
      )}
      {_renderTopSection()}
      {_renderCostSection()}
      {_renderDivider()}
      {_renderInvoiceSettings()}
      {currentPaymentMethod !== 'Steddi' && <>{_renderDivider()}</>}
      {_renderHasAutomaticPayment()}
      {!hasAutomaticPayment && _renderInvoiceDetails()}
      {_renderBottomNavigation()}
    </Card>
  )
}