import React, { useState } from 'react'
import {
  BaseComponent,
  BrandColors,
  ContentGrid,
  getCounterTheme,
  IAction,
  IBaseComponent,
  IImage,
  Image,
  StyleGrid,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import {
  h3TextPrefab,
  h4TextPrefab,
  IServiceArticleTemplate,
  MS_ButtonTemplate,
  paragraphTextPrefab,
  ServiceArticleTemplate
} from '../../Prefabs'
import { MSRichText } from '../MSRichText/MSRichText'
import { ActionButton } from '../../components'
import classNames from 'classnames'
import { v4 as uuid4 } from 'uuid'
import { useApplicationContext } from '../../contexts'
import './ArticleItem.scss'

export interface IArticleItem extends Omit<IBaseComponent, 'template'> {
  label?: string
  title?: string
  description: string
  additions?: string[]
  action: IAction
  image: IImage
  pitchHeader?: {
    image: IImage
    title: string
    text?: string
  }
  template?: IServiceArticleTemplate
  onClose?: () => void
}

export const ArticleItem = (props: IArticleItem) => {
  // ************************************
  // Properties
  // ************************************

  const { desktopView } = useApplicationContext()
  const {
    id,
    className,
    theme = 'Light',
    brand,
    template = ServiceArticleTemplate(theme, desktopView),
    title,
    label,
    description,
    additions,
    action,
    image,
    pitchHeader
  } = props
  const classPrefix = 'ms-article-item'
  const descWrapperId = uuid4()
  const clampHeight: number = 100

  // ************************************
  // Lifecycle
  // ************************************

  const [descWrapperHeight, setDescWrapperHeight] = useState<number>(0)

  // ************************************
  // Helpers
  // ************************************

  const _canRenderAdditions = () => {
    return additions && additions.length > 0
  }

  const _getBottomContentAlignment = () => {
    if (desktopView) {
      return !_canRenderAdditions() ? 'center-right' : 'center-left'
    } else {
      return 'center'
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderAdditions = () => {
    if (_canRenderAdditions() && additions) {
      return additions.map((addition: string) => {
        return (
          <Text
            key={uuid4()}
            {...paragraphTextPrefab()}
            palette={template?.palette}
            weight={500}
          >
            {`● ${addition}`}
          </Text>
        )
      })
    }
  }

  const _renderPitchHeader = () => {
    if (pitchHeader) {
      return (
        <StyleGrid
          className={`${classPrefix}__content-section__pitch-header`}
          direction='row'
          alignment={pitchHeader.text ? 'top-left' : 'center-left'}
          boxSizing='border-box'
          gap={2}
        >
          <Image
            {...pitchHeader.image}
            className={`${classPrefix}__content-section__pitch-header__image`}
            theme={theme}
            brand={brand}
            scalingBy='width'
            fit={'cover'}
            size={{
              x: 50,
              y: 50
            }}
          />
          <ContentGrid
            className={`${classPrefix}__content-section__pitch-header__content`}
            direction='column'
            alignment='center-left'
            gap={4}
            gapType='px'
            boxSizing='border-box'
            tagType='section'
          >
            {pitchHeader.title && (
              <Text
                {...h4TextPrefab()}
                theme={getCounterTheme(theme)}
                brand={brand}
              >
                {pitchHeader.title}
              </Text>
            )}
            {pitchHeader.text && (
              <Text
                {...paragraphTextPrefab()}
                theme={getCounterTheme(theme)}
                brand={brand}
              >
                {pitchHeader.text}
              </Text>
            )}
          </ContentGrid>
        </StyleGrid>
      )
    }
  }

  const _renderContent = () => {
    return (
      <BaseComponent
        id={descWrapperId}
        className={classNames(`${classPrefix}__content-section`, {
          [`${classPrefix}__content-section--long`]: descWrapperHeight > clampHeight && desktopView
        })}
        theme={theme}
        brand={brand}
        template={template}
        handleOnRender={() => {
          let ele = document.getElementById(descWrapperId)

          if (ele) {
            setDescWrapperHeight(ele.getBoundingClientRect().height)
          }
        }}
      >
        {_renderPitchHeader()}
        {title && (
          <Text
            className={`${classPrefix}__content-section__title`}
            {...h3TextPrefab()}
            color={BrandColors['primary-shade-light-2']}
          >
            {title}
          </Text>
        )}
        <MSRichText
          text={description}
          className={`${classPrefix}__content-section__description`}
          theme={getCounterTheme(theme)}
          brand={brand}
          alignment='lawful'
        />
        <StyleGrid
          className={`${classPrefix}__content-section__bottom`}
          direction={desktopView ? 'row' : 'column'}
          gap={desktopView ? 0 : 4}
          alignment={_getBottomContentAlignment()}
          spaceBetween={_canRenderAdditions()}
        >
          {_canRenderAdditions() && (
            <ContentGrid
              className={`${classPrefix}__content-section__bottom__additions`}
              tagType='section'
              alignment='top-left'
              direction={'column'}
            >
              {_renderAdditions()}
            </ContentGrid>
          )}
          <ContentGrid
            tagType='nav'
            alignment='center-right'
            direction={'column'}
          >
            <ActionButton
              template={MS_ButtonTemplate(theme, 'primary')}
              action={action}
              padding={desktopView ? 'medium' : 'default'}
              brand={brand}
            />
          </ContentGrid>
        </StyleGrid>
      </BaseComponent>
    )
  }

  const _renderImage = () => {
    return (
      <Image
        {...image}
        className={`${classPrefix}__image-section`}
        template={template.image}
        fit='cover'
        scalingBy='fill'
      >
        {label && (
          <BaseComponent
            className={`${classPrefix}__image-section__label`}
            brand={brand}
            template={template.label}
          >
            <Text
              {...paragraphTextPrefab()}
              weight={500}
              brand={brand}
            >
              {label}
            </Text>
          </BaseComponent>
        )}
      </Image>
    )
  }

  // ************************************
  // Render
  // ************************************

  return (
    <StyleGrid
      id={id}
      className={classNames(classPrefix, {
        [`${className}`]: className
      })}
      direction={desktopView ? 'row' : 'column'}
      alignment={descWrapperHeight > clampHeight ? 'top-right' : 'center-right'}
    >
      {_renderImage()}
      {_renderContent()}
    </StyleGrid>
  )
}