import { format } from 'date-fns'
import { getRequest } from '../../../contexts'
import {
  ICustomerElectricityAgreement,
  ICustomerInstallation,
  ICustomerTimeSeries,
  IMonthlyManagedResult,
  IYearlyManagedResult
} from '../../../models'
import { fetchSpotPriceHistory, getMonths, getText } from '../../../services'
import { nb } from 'date-fns/locale'
import { BrandColors, IconType } from '@fjordkraft/fjordkraft.component.library'
import { IChartAccordion, IChartAccordionItem } from '../../../blocks'
import { PointPlankTemplateNegative, PointPlankTemplatePositive } from '../../../Prefabs'

// ************************************
// General charts
// ************************************

export const getChartData = async (translation: any, installation: ICustomerInstallation, GET: getRequest) => {
  let data
  let series: any[] = []
  let categories: string[] = []
  let unit = ''
  let desc = ''

  let series1: any = {
    name: getText('series1Name', translation),
    data: []
  }

  data = await fetchSpotPriceHistory(GET, installation)

  if (data?.timeSeries?.length > 0) {
    let timeSeriesData = getSeriesData(data.timeSeries, translation)

    unit = data.unit
    series1.data = timeSeriesData.data
    categories = timeSeriesData.categories
    series.push(series1)
  }

  return series.length > 0
    ? {
        series,
        categories,
        chartTooltip: {
          y: {
            suffix: unit
          }
        },
        cardHeader: {
          title: '',
          subTitle: ''
        },
        description: desc
      }
    : null
}

const getSeriesData = (dataSeries: ICustomerTimeSeries[], translation: any) => {
  let categories: string[] = []
  let data: any[] = []

  dataSeries.forEach((d: ICustomerTimeSeries) => {
    let months: string[] = getMonths()
    categories.push(months[new Date(d.startTime).getMonth()])
    data.push(d.cost)
  })

  return { data, categories }
}

// ************************************
// Managed charts
// ************************************

export const getParsedChartAccordionData = (
  agreement: ICustomerElectricityAgreement,
  translation: any,
  desktopView: boolean,
  priceArea: string
) => {
  let value: number =
    priceArea === 'NO4' ? (agreement.managedResult?.sum ?? 0) : (agreement.managedResult?.sumInclVat ?? 0)

  let accordionChartData: IChartAccordion = {
    overview: {
      points: value,
      pointsLabel: getText('currency', translation),
      useDecimals: true,
      template: value > 0 ? PointPlankTemplatePositive('Light', 'top') : PointPlankTemplateNegative('Light', 'top'),
      pointsColor: value > 0 ? BrandColors['status-shade-light-1'] : BrandColors['status-shade-light-3'],
      left: {
        title: getText('chartAccordionTitle', translation),
        description: getText(value > 0 ? 'chartAccordionDescriptionGain' : 'chartAccordionDescriptionLoss', translation)
      },
      toast: {
        text: getText(value > 0 ? 'managedPositiveResult' : 'managedNegativeResult', translation),
        icon: value > 0 ? IconType.SuccessBubble : IconType.InfoBubble,
        status: value > 0 ? 'positive' : 'neutral'
      },
      desktopView
    },
    header: {
      leftText: getText('chartAccordionColumnTitle1', translation),
      rightText: getText('chartAccordionColumnTitle2', translation)
    },
    translations: translation,
    items: _getChartAccordionPlanks(agreement, translation, priceArea)
  }

  return accordionChartData
}

const _getChartAccordionPlanks = (agreement: ICustomerElectricityAgreement, translation: any, priceArea: string) => {
  let items: IChartAccordionItem[] = []

  if (agreement.managedResult?.yearly && agreement.managedResult.yearly.length > 0) {
    agreement.managedResult.yearly.forEach((result: IYearlyManagedResult) => {
      let amount: number = priceArea === 'NO4' ? (result?.sum ?? 0) : (result?.sumInclVat ?? 0)
      let seriesData: any = _getChartAccordionSeriesData(result, translation, priceArea)

      items.push({
        leftText: `${result.year}`,
        rightText: `${amount} ${getText('currency', translation)}`,
        rightColor: amount > 0 ? BrandColors['status-shade-light-1'] : BrandColors['status-shade-light-3'],
        chartData: {
          series: seriesData.series,
          colors: [BrandColors['secondary-shade-light-1'], BrandColors['action-shade-dark-1']],
          totalYAmount: seriesData.totalAmount
        }
      })
    })
  }

  return items
}

const _getChartAccordionSeriesData = (result: IYearlyManagedResult, translation: any, priceArea: string) => {
  let series: any[] = []
  let totalAmount: number = 0
  let totalPositive: number = 0
  let totalNegative: number = 0
  let positiveSeries = {
    name: getText('managedSeries1Title', translation),
    totalYAmount: 0,
    data: _getPrefilledData(result.year)
  }
  let negativeSeries = {
    name: getText('managedSeries2Title', translation),
    totalYAmount: 0,
    data: _getPrefilledData(result.year)
  }

  if (result.monthly?.length > 0) {
    result.monthly.forEach((month: IMonthlyManagedResult, i: number) => {
      let date: Date = new Date(result.year, month.month - 1)
      let cat: string = format(date, 'MMM', { locale: nb })
      let amount: number = priceArea === 'NO4' ? month.result : month.resultIncVat

      if (amount > 0) {
        totalPositive += amount
        positiveSeries.data.splice(month.month - 1, 1, {
          x: cat,
          y: amount,
          date
        })
      } else {
        totalNegative += amount
        negativeSeries.data.splice(month.month - 1, 1, {
          x: cat,
          y: amount,
          date
        })
      }

      totalAmount += amount
    })

    positiveSeries.totalYAmount = totalPositive
    negativeSeries.totalYAmount = totalNegative
  }

  if (totalPositive > 0) {
    series.push(positiveSeries)
  }

  if (totalNegative < 0) {
    series.push(negativeSeries)
  }

  return { series, totalAmount }
}

const _getPrefilledData = (year: number) => {
  let data: any[] = []

  for (let i = 0; i < 12; i++) {
    let date: Date = new Date(year, i, 1)
    let cat: string = format(date, 'MMM', { locale: nb })

    data.push({
      x: cat,
      y: 0,
      date
    })
  }

  return data
}
