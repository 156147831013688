import { ISectionBlock } from '../../../../blocks'
import { IServices, IServiceSectionBlock, IServiceStatusDescriptions, IUser, ServiceStatus } from '../../../../models'
import { fetchPowerPlant } from '../../../../services/collection/InstallationService'
import { getServiceEpiDataBasedOnStatus } from './ServicePageDataDescriptions'

// ************************************
// PUBLIC
// ************************************

export const getServicePageDataSections = async (
  translations: any,
  user: IUser,
  services: IServices,
  status: ServiceStatus
): Promise<ISectionBlock[] | undefined> => {
  let norwegianWaterPowerLocalPowerPlant = await _getLocalPowerPlant(translations, user, services)
  let sectionBlocks: ISectionBlock[] = []
  let relevantDescription: IServiceStatusDescriptions | undefined = getServiceEpiDataBasedOnStatus(translations, status)

  if (relevantDescription) {
    if (relevantDescription.sectionBlocks?.length > 0) {
      relevantDescription.sectionBlocks.forEach((section: IServiceSectionBlock) => {
        sectionBlocks.push({
          image: {
            src: section.image,
            alt: `${section.title} bilde`
          },
          roundImage: section.roundImage,
          imagePlacement: section.imagePlacement,
          action: section.actionText
            ? {
                text: section.actionText
              }
            : undefined,
          content: {
            logo: {
              src: section.logo,
              alt: `${section.title} logo`
            },
            title: section.title,
            subTitle: section.subTitle,
            description: section.description
          }
        } as ISectionBlock)
      })
    }
  }

  if (norwegianWaterPowerLocalPowerPlant) {
    sectionBlocks.push({
      content: {
        title: norwegianWaterPowerLocalPowerPlant.name,
        description: norwegianWaterPowerLocalPowerPlant.content
      },
      image: {
        src: norwegianWaterPowerLocalPowerPlant.picture ?? translations.imageSrc
      },
      imagePlacement: 'left'
    } as ISectionBlock)
  }

  return sectionBlocks
}

// ************************************
// PRIVATE
// ************************************

const _getLocalPowerPlant = async (translations: any, user: IUser, services: IServices) => {
  const { installation } = user
  const { GET } = services

  if (translations.servicePageId === 'norskvannkraft' && installation) {
    if (installation.address.priceArea) {
      let resp = await fetchPowerPlant(GET, installation.address.priceArea)

      return resp
    }
  }
}