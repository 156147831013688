import React, { useEffect, useMemo, useState } from 'react'
import {
  ApplicationDefaultContext,
  useApplicationContext,
  useApplicationCoreDataContext,
  useApplicationServicehandlerContext
} from '../../../contexts'
import { ICustomerAccountInformation, IDefaultInputProps, IDefaultProps } from '../../../models'
import { Theme } from '@fjordkraft/fjordkraft.component.library'
import _ from 'lodash'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import { convertMapToObject } from '../../../services'
import { useLocation } from 'react-router-dom'

const DEFAULT_PROPS: Map<string, any> = new Map<string, any>([
  ['activeBrand', undefined],
  ['activeTheme', undefined],
  ['desktopView', undefined],
  ['epiChildren', undefined],
  ['isGuest', undefined],
  ['services', undefined],
  ['translations', undefined],
  ['relationship', undefined],
  ['user', undefined]
])

export const ApplicationDefaultContextWrapper = (props: any) => {
  // ************************************
  // Properties
  // ************************************

  const { desktopView, activeBrand, activeTheme } = useApplicationContext()
  const {
    isGuest,
    hostIdForCustomerDataRequests,
    hosts,
    guests,
    chosenHost,
    mainUser,
    setChosenHost,
    setHostIdForCustomerDataRequests
  } = useApplicationGuestsAndHostsContext()
  const { GET, GETTYPED, POST, PUT, DELETE, user, token, customerServiceFeature } =
    useApplicationServicehandlerContext()
  const { epiChildren, userData, installation, translation, setInstallation } = useApplicationCoreDataContext()

  const defaultOptionalDependencies = ['translations']

  // ************************************
  // Lifecycle
  // ************************************

  const [defaultProps, setDefaultProps] = useState<IDefaultProps>({} as IDefaultProps)
  const [activeAccount, setActiveAccount] = useState<ICustomerAccountInformation>()
  const location = useLocation()

  const context = useMemo(() => {
    return { defaultProps, activeAccount, setActiveAccount }
  }, [defaultProps, activeAccount])

  useEffect(() => {
    _updateDefaultProps({
      activeBrand,
      activeTheme,
      desktopView,
      epiChildren,
      translations: translation,
      user: {
        installation,
        setInstallation,
        userData,
        mainUser
      },
      relationship: {
        isGuest,
        hostIdForCustomerDataRequests,
        hosts,
        guests,
        chosenHost,
        setChosenHost,
        setHostIdForCustomerDataRequests
      },
      services: {
        token,
        user,
        customerServiceFeature,
        GET,
        GETTYPED,
        POST,
        PUT,
        DELETE
      }
    })
  }, [
    isGuest,
    hostIdForCustomerDataRequests,
    hosts,
    guests,
    chosenHost,
    mainUser,
    activeBrand,
    activeTheme,
    desktopView,
    epiChildren,
    installation,
    userData,
    user,
    token,
    translation,
    customerServiceFeature,
    setHostIdForCustomerDataRequests,
    setChosenHost,
    setInstallation,
    GET,
    GETTYPED,
    POST,
    PUT,
    DELETE,
    location
  ])

  // ************************************
  // Default data handling
  // ************************************

  const _getPropertyData = (key: string, props: any) => {
    let returnValue = undefined

    if (DEFAULT_PROPS.get(key)) {
      if (props[key] && !_.isEqual(DEFAULT_PROPS.get(key), props[key])) {
        returnValue = props[key]
      } else {
        returnValue = DEFAULT_PROPS.get(key)
      }
    } else {
      returnValue = props[key]
    }

    return returnValue
  }

  const _hasAllDependenciesInMap = (deps: Map<string, any>, ignore: string[] = []): boolean => {
    let hasAllDependencies: boolean = true

    if (deps && deps.size > 0) {
      for (let ignoreKey of ignore) {
        for (let [key, value] of deps) {
          if (key !== ignoreKey) {
            if (value === undefined || value === null) {
              hasAllDependencies = false
              break
            }
          }
        }
      }
    }

    return hasAllDependencies
  }

  const _updateDefaultProps = (inputProps: IDefaultInputProps, firstRender: boolean = false) => {
    let brand: string = _getPropertyData('activeBrand', inputProps)
    let theme: Theme = _getPropertyData('activeTheme', inputProps)
    let desktopView: boolean = _getPropertyData('desktopView', inputProps)
    let user: boolean = _getPropertyData('user', inputProps)
    let epiChildren: any = _getPropertyData('epiChildren', inputProps)
    let isGuest: boolean = _getPropertyData('isGuest', inputProps) ?? false
    let services: any = _getPropertyData('services', inputProps)
    let relationship: any = _getPropertyData('relationship', inputProps)
    let translations: any = _getPropertyData('translations', inputProps)

    DEFAULT_PROPS.set('activeBrand', brand)
    DEFAULT_PROPS.set('activeTheme', theme)
    DEFAULT_PROPS.set('desktopView', desktopView)
    DEFAULT_PROPS.set('epiChildren', epiChildren)
    DEFAULT_PROPS.set('isGuest', isGuest)
    DEFAULT_PROPS.set('services', services)
    DEFAULT_PROPS.set('relationship', relationship)
    DEFAULT_PROPS.set('user', user)
    DEFAULT_PROPS.set('translations', translations)

    if (_hasAllDependenciesInMap(DEFAULT_PROPS)) {
      const defaultPropsObject = convertMapToObject(DEFAULT_PROPS)

      if (firstRender) return defaultPropsObject

      setDefaultProps(defaultPropsObject)
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderContent = useMemo(() => {
    if (defaultProps && _hasAllDependenciesInMap(DEFAULT_PROPS, defaultOptionalDependencies)) {
      return props.children
    } else {
      return <></>
    }
  }, [defaultProps])

  // ************************************
  // Render
  // ************************************

  return (
    <>
      <ApplicationDefaultContext.Provider value={context}>{_renderContent}</ApplicationDefaultContext.Provider>
    </>
  )
}