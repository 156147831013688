import {
  IMasterPlankTemplate,
  LocationType,
  MasterPlankTemplate,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

export const MS_MasterPlankTemplate = (theme: Theme, location: LocationType) => {
  let base: IMasterPlankTemplate = MasterPlankTemplate(theme, location)

  if (base?.leftColumn2?.transform?.grid) {
    base.leftColumn2.transform.grid.scaling = 4
  }

  if (base?.rightColumn1?.transform?.grid) {
    base.rightColumn1.transform.grid.scaling = 2
  }

  if (base.bottom?.transform?.border) {
    if (location === 'bottom' || location === 'single') {
      base.bottom.transform.border.radiusLocation = 'bottom'
      base.bottom.transform.border.radiusType = 'curved'
    }
  }

  return base
}