import {
  IAddonData,
  ICustomerInstallation,
  IServicePage,
  IServiceStatusDescriptions,
  OnClickServiceOrderActionEnum,
  OrderMethodType,
  OrderMethodTypeEnum,
  ServiceOrderSteps,
  ServiceOrderStepsEnum,
  ServiceStatus,
  ServiceStatusDescription,
  ServiceStatusDescriptionEnum,
  ServiceStatusEnum,
  ServiceTypesEnum
} from '../../models'
import { IMSPlankWall, IPlankHouse } from '../../blocks'
import { IServiceBasePageData } from '../../pages/ServicesPagesWrapper/ServicePage/ServiceBasePageData'
import {
  getAdditionalAddonServicesPointsWrapper,
  getAddonActiveFutureFromDate,
  getCoreDetailPointsWrapper,
  shouldUseDescription
} from '../../pages/ServicesPagesWrapper/ServicePage/Datahandling'
import { Constants } from '../../data'
import { IOption } from '@fjordkraft/fjordkraft.component.library'
import { ICardPoint } from '../../components'
import { getPlankPrefab } from '../../Prefabs'
import { typedGetRequest } from '../../contexts'
import { getText } from '../../services'

export const getInitialStep = (predefinedStatusStep?: ServiceOrderSteps): ServiceOrderSteps => {
  if (predefinedStatusStep) return predefinedStatusStep
  return ServiceOrderStepsEnum.DEFAULT
}

const _filterPointsIntoWalls = (
  pointList: ICardPoint[],
  orderMethodType: OrderMethodType,
  installation?: ICustomerInstallation
) => {
  let wallTop: IMSPlankWall = { planks: [] }
  let wallMiddle: IMSPlankWall = { planks: [] }
  let wallBottom: IMSPlankWall = { planks: [] }

  const _getPointPlank = (point: ICardPoint) => {
    return getPlankPrefab('Text', {
      left: {
        title: point.leftText,
        description: point.extraLeftText
      },
      right: {
        title: point.rightText,
        description: point.extraRightText
      }
    })
  }

  if (pointList && pointList.length > 0) {
    for (let point of pointList) {
      if (
        orderMethodType === OrderMethodTypeEnum.INSTALLATION &&
        installation &&
        point.id === 'installationAddressPlank'
      ) {
        wallTop.planks.push(_getPointPlank(point))
      } else if (point.subDetail) {
        wallBottom.planks.push(_getPointPlank(point))
      } else {
        wallMiddle.planks.push(_getPointPlank(point))
      }
    }
  }
  return { wallTop, wallMiddle, wallBottom }
}

const getSubscribedPlankData = async (data: IPlankData) => {
  const { serviceDescriptions, status, selectedSubService, page, installation, GETTYPED, addonStates } = data
  let blockData: any = _getRelatedDynamicInfoBlock(serviceDescriptions, status)
  const pointConfig = {
    services: { GETTYPED },
    translations: page,
    user: { installation },
    addonStates
  } as IServiceBasePageData

  const { points } = await getCoreDetailPointsWrapper(pointConfig, blockData)
  const additionalAddonPoints = await getAdditionalAddonServicesPointsWrapper(pointConfig, status)

  if (additionalAddonPoints && selectedSubService?.value !== 'none') {
    const pointList = points.concat(additionalAddonPoints)
    return _filterPointsIntoWalls(pointList, page.orderMethodType, installation)
  }
  return _filterPointsIntoWalls(points, page.orderMethodType, installation)
}

const _getRelatedDynamicInfoBlock = (serviceDescriptions: IServiceStatusDescriptions[], status: ServiceStatus) => {
  let dynamicInfoBlock: any = undefined

  if (!serviceDescriptions?.length) return dynamicInfoBlock
  serviceDescriptions.forEach((statusDesc: IServiceStatusDescriptions) => {
    let desc = shouldUseDescription(statusDesc, status) ? statusDesc : undefined

    if (desc?.infoBlocks && desc.infoBlocks.length > 0) {
      for (let info of desc.infoBlocks) {
        if (info.blockId === Constants.services.dynamicInfoDetailsId) {
          dynamicInfoBlock = info
          break
        }
      }
    }
  })

  return dynamicInfoBlock
}

const getUnsubscribedPlankData = async (data: IPlankData) => {
  const { status, selectedSubService, page, installation, GETTYPED, addonStates } = data

  let pointList: ICardPoint[] = []
  let additionalAddonPoints = await getAdditionalAddonServicesPointsWrapper(
    {
      services: {
        GETTYPED
      },
      user: {
        installation
      },
      translations: page,
      addonStates
    } as IServiceBasePageData,
    status
  )

  if (page.orderMethodType === OrderMethodTypeEnum.INSTALLATION && page.servicePageId !== ServiceTypesEnum.TRUMF) {
    pointList.push({
      id: 'installationAddressPlank',
      leftText: getText('address', page),
      rightText: installation?.address?.streetAddress ?? ''
    })
  }

  if (installation) {
    const activeFromDate = getAddonActiveFutureFromDate(installation, addonStates, page.productDefinitionId)
    if (activeFromDate) {
      pointList.push({
        leftText: getText('activeFrom', page),
        rightText: activeFromDate
      })
    }
  }

  if (additionalAddonPoints && selectedSubService?.value !== 'none') {
    pointList = pointList.concat(additionalAddonPoints)
  }

  return _filterPointsIntoWalls(pointList, page.orderMethodType, installation)
}

interface IPlankData {
  serviceDescriptions: IServiceStatusDescriptions[]
  status: ServiceStatus
  selectedSubService: IOption | undefined
  page: IServicePage
  installation: ICustomerInstallation | undefined
  GETTYPED: typedGetRequest
  addonStates: IAddonData[] | undefined
}

export const getInformationStepHouse = async (
  hasSubscription: boolean,
  plankProps: IPlankData
): Promise<IPlankHouse> => {
  let house: IPlankHouse = { plankWalls: [], wallGap: 1 }
  let walls

  if (!hasSubscription) {
    walls = await getSubscribedPlankData(plankProps)
  } else {
    walls = await getUnsubscribedPlankData(plankProps)
  }

  if (walls?.wallTop && walls.wallTop.planks.length > 0) {
    house.plankWalls.push(walls.wallTop)
  }

  if (walls?.wallMiddle && walls.wallMiddle.planks.length > 0) {
    house.plankWalls.push(walls.wallMiddle)
  }

  if (walls?.wallBottom && walls.wallBottom.planks.length > 0) {
    house.plankWalls.push(walls.wallBottom)
  }

  return house
}

export const initialSelectOption = {
  [ServiceStatusEnum.ACTIVE]: OnClickServiceOrderActionEnum.CANCEL,
  [ServiceStatusEnum.TERMINATING]: OnClickServiceOrderActionEnum.CANCEL,
  [ServiceStatusEnum.ACTIVE_FUTURE]: OnClickServiceOrderActionEnum.CANCEL,
  [ServiceStatusEnum.ACTIVATING]: OnClickServiceOrderActionEnum.CANCEL,
  [ServiceStatusEnum.ORDER_IN_PROGRESS]: OnClickServiceOrderActionEnum.CANCEL,
  [ServiceStatusEnum.ORDER_WAITING_FOR_CUSTOMER]: OnClickServiceOrderActionEnum.CANCEL,

  [ServiceStatusEnum.INACTIVE]: OnClickServiceOrderActionEnum.ORDER,
  [ServiceStatusEnum.TERMINATED]: OnClickServiceOrderActionEnum.ORDER,
  [ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER]: OnClickServiceOrderActionEnum.ORDER,
  [ServiceStatusEnum.ORDER_FAILED]: OnClickServiceOrderActionEnum.ORDER
}

export const serviceStatusToDescription = (status: ServiceStatus): ServiceStatusDescription => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.TERMINATING:
    case ServiceStatusEnum.ACTIVE_FUTURE:
      return ServiceStatusDescriptionEnum.SUBSCRIBED
    case ServiceStatusEnum.INACTIVE:
    case ServiceStatusEnum.TERMINATED:
    case ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER:
    case ServiceStatusEnum.ORDER_FAILED:
      return ServiceStatusDescriptionEnum.UNSUBSCRIBED
    case ServiceStatusEnum.ACTIVATING:
    case ServiceStatusEnum.ORDER_IN_PROGRESS:
    case ServiceStatusEnum.ORDER_WAITING_FOR_CUSTOMER:
      return ServiceStatusDescriptionEnum.PENDING
    default:
      return ServiceStatusDescriptionEnum.DEFAULT
  }
}

export const getServiceDescriptionForStatus = (
  status: ServiceStatus,
  serviceDescriptions: IServiceStatusDescriptions[]
): IServiceStatusDescriptions | undefined => {
  let data: IServiceStatusDescriptions | undefined = undefined

  serviceDescriptions?.forEach((desc: IServiceStatusDescriptions) => {
    let epiState: ServiceStatusDescription = serviceStatusToDescription(status)
    if (desc.status === epiState || epiState === ServiceStatusDescriptionEnum.DEFAULT) {
      data = desc
    }
  })
  return data
}