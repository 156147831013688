import {
  IServicePopupCheckbox,
  ITranslationItem,
  OnClickServiceOrderAction,
  ServiceOrderSteps,
  ServiceOrderStepsEnum
} from '../../../models'
import { StepContentWrapper } from './StepContentWrapper'
import { capitalizeFirstLetter } from '../../../services'
import { IAction, LoaderGraphic, Theme } from '@fjordkraft/fjordkraft.component.library'
import { InformationStep } from './InformationStep'
import React from 'react'

import FailureSVG from '../../../assets/art/service-order-failure.svg?react'
import WaitingSVG from '../../../assets/art/service-order-waiting.svg?react'
import SuccessSVG from '../../../assets/art/service-order-success.svg?react'
import { IPlankHouse } from '../../../blocks'
import { IDescriptionPoint } from '../../../components'
import { OrderOrCancelStep } from './OrderOrCancelStep'
import { useApplicationAddonServicesContext } from '../../../contexts'

export interface IPopupContentData {
  dataPoints: IDescriptionPoint[]
  texts: ITranslationItem[]
  house?: IPlankHouse
  selectedExtraProductDesc?: string
  description: string
  links: IAction[]
}
interface IPopupContent {
  currentStep: ServiceOrderSteps
  classPrefix: string
  selectedOption: OnClickServiceOrderAction
  isTrumf: boolean
  theme: Theme
  brand?: string
  checkboxStates?: IServicePopupCheckbox[]
  setCheckboxStates: (value: IServicePopupCheckbox[]) => void
  productDefinitionId: string
  data: IPopupContentData
  onClickSelectOption: (val: OnClickServiceOrderAction | any) => void
}
export const PopupContent = ({
  currentStep,
  classPrefix,
  data,
  selectedOption,
  isTrumf,
  theme,
  brand,
  checkboxStates,
  setCheckboxStates,
  productDefinitionId,
  onClickSelectOption
}: IPopupContent) => {
  const { texts } = data
  const { addonStates } = useApplicationAddonServicesContext()
  switch (currentStep) {
    case ServiceOrderStepsEnum.FAILURE:
    case ServiceOrderStepsEnum.ERROR:
      return (
        <StepContentWrapper
          classPrefix={classPrefix}
          texts={texts}
          textKey={'failureConclusion'}
        >
          <FailureSVG />
        </StepContentWrapper>
      )
    case ServiceOrderStepsEnum.IN_PROGRESS:
      return (
        <StepContentWrapper
          classPrefix={classPrefix}
          texts={texts}
          textKey={isTrumf ? '' : 'waitingConclusion'}
        >
          <WaitingSVG />
        </StepContentWrapper>
      )
    case ServiceOrderStepsEnum.SUCCESS:
      return (
        <StepContentWrapper
          classPrefix={classPrefix}
          texts={texts}
          textKey={isTrumf ? `successConclusion${capitalizeFirstLetter(selectedOption)}` : 'successConclusion'}
        >
          <SuccessSVG />
        </StepContentWrapper>
      )
    case ServiceOrderStepsEnum.REDIRECT_SUCCESS:
      return (
        <StepContentWrapper
          classPrefix={classPrefix}
          texts={texts}
          textKey={isTrumf ? `successConclusionOrder` : 'successConclusion'}
        >
          <SuccessSVG />
        </StepContentWrapper>
      )
    case ServiceOrderStepsEnum.LOADING:
      return (
        <StepContentWrapper
          classPrefix={classPrefix}
          texts={texts}
          textKey={isTrumf ? `loadingText${capitalizeFirstLetter(selectedOption)}` : 'loadingText'}
        >
          <LoaderGraphic
            theme={theme}
            brand={brand}
            thickness={'regular'}
            scaleThickness={0.5}
            size={4}
          />
        </StepContentWrapper>
      )
    case ServiceOrderStepsEnum.SELECT:
      return (
        <OrderOrCancelStep
          theme={theme}
          texts={texts}
          classPrefix={classPrefix}
          productDefinitionId={productDefinitionId}
          addonStates={addonStates}
          selectedOption={selectedOption}
          onClick={onClickSelectOption}
        />
      )
    default:
      return (
        <InformationStep
          classPrefix={classPrefix}
          theme={theme}
          brand={brand}
          data={data}
          checkboxStates={checkboxStates}
          setCheckboxStates={setCheckboxStates}
        />
      )
  }
}