import './styling/styling.scss'

import React, { useEffect, useMemo, useState } from 'react'
import { ApplicationContext } from './contexts/variations/ApplicationContext'
import { Theme } from '@fjordkraft/fjordkraft.component.library'
import { Constants } from './data'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { SeasonalMode } from './models'
import { Log } from 'oidc-client-ts'
import {
  ApplicationAccountSelectWrapper,
  ApplicationCoreDataWrapper,
  ApplicationDefaultContextWrapper,
  ApplicationGuestsAndHostsWrapper,
  ApplicationOverlayWrapper,
  ApplicationServiceWrapper,
  BenefitsIntroductionPage,
  BenefitsOverviewPage,
  BenefitsPage,
  ConsentPage,
  CurrentInvoicesPage,
  DefaultPageLayout,
  EnergyAgreementPage,
  EnergyConsumptionCostPage,
  EnergyConsumptionPage,
  EnergyConsumptionUsePage,
  GuestAdminPage,
  InboxPage,
  InvoiceFixedDueDatePage,
  InvoiceHistoryPage,
  InvoiceSelectedPage,
  InvoiceSettingsPage,
  KindlyWrapper,
  MoveOutPage,
  MoveOverviewPage,
  MyBenefitsPage,
  MyCommunicationSettingsPage,
  MyPageHome,
  NotFoundPage,
  ReservationPage,
  ResidencesPage,
  ServiceChartPage,
  ServicePage,
  ServicesOverviewPage,
  ServiceTransactionsPage,
  ServiceWrapperPage,
  SpotPricePage,
  SubPageLayout,
  TransactionsPage,
  UsePointsInvoicePage,
  UsePointsPage,
  UsePointsWebshopPage
} from './pages'
import { OnboardingPage } from './pages/OnboardingPage/OnboardingPage'
import { ApplicationConsentWrapper } from './pages/DatahandlerWrappers/ApplicationConsentWrapper/ApplicationConsentWrapper'
import { ApplicationAddonServicesWrapper } from './pages/DatahandlerWrappers/ApplicationAddonServicesWrapper/ApplicationAddonServicesWrapper'
import { HomePageWrapper } from './pages/HomePage/HomePageWrapper'
import { PowerCustomerGuard } from './components/Routing/PowerCustomerGuard'

if (import.meta.env.REACT_APP_ENABLE_AUTH_LOGGING) {
  Log.setLogger(console)
}

const App = () => {
  // ************************************
  // Lifecycle
  // ************************************

  const [activeTheme, setActiveTheme] = useState<Theme>('Light')
  const [activeBrand, setActiveBrand] = useState<string>(import.meta.env.REACT_APP_UI_BRAND ?? 'brand-default')
  const [desktopView, setDesktopView] = useState<boolean>(true)
  const [seasonalMode, setSeasonalMode] = useState<SeasonalMode>('None')
  const [locales, setLocales] = useState<string>(Constants.locales ?? 'nb')

  useEffect(() => {
    _updateSize()
    _updateSeasonalMode()

    window.addEventListener('resize', _updateSize)
    return () => {
      window.removeEventListener('resize', _updateSize)
    }
  })

  const contextHandler = useMemo(() => {
    return {
      locales,
      activeTheme,
      activeBrand,
      setLocales,
      setActiveTheme,
      setActiveBrand,
      desktopView,
      seasonalMode,
      setSeasonalMode
    }
  }, [activeTheme, activeBrand, desktopView, seasonalMode, locales])

  // ************************************
  // Helper Functionality
  // ************************************

  const _updateSeasonalMode = () => {
    let date = new Date()

    switch (date.getMonth()) {
      case 10:
      case 0:
      case 9:
        setSeasonalMode('Winter')
        break
      case 11:
        setSeasonalMode('Christmas')
        break
      default:
        setSeasonalMode('None')
        break
    }
  }

  const _updateSize = () => {
    let wrapper = document.getElementById('root')
    let appWidth = 0

    if (wrapper) {
      appWidth = wrapper.getBoundingClientRect().width

      setDesktopView(appWidth > Constants.mobileBreak)
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderHomePage = () => {
    return (
      <>
        <Route
          index
          element={<HomePageWrapper />}
        />
        <Route
          path={Constants.paths.energyPage}
          element={<HomePageWrapper />}
        />
        <Route
          path={Constants.paths.oldFrontPage}
          element={<HomePageWrapper />}
        />
      </>
    )
  }

  const _renderServices = () => {
    return (
      <Route
        path={Constants.paths.servicesPage}
        element={
          <PowerCustomerGuard>
            <ServiceWrapperPage />
          </PowerCustomerGuard>
        }
      >
        <Route
          index
          element={<ServicesOverviewPage />}
        />
        <Route
          path={`${Constants.paths.servicesPage}/:serviceId`}
          element={<ServicePage />}
        />
        <Route
          path={`${Constants.paths.servicesPage}/forutsigbar/transaksjoner/:predictablePaymentAccountNumber`}
          element={<ServiceTransactionsPage serviceId='forutsigbar' />}
        />
        <Route
          path={`${Constants.paths.servicesPage}/trumf/transaksjoner`}
          element={<ServiceTransactionsPage serviceId='trumf' />}
        />
        <Route
          path={`${Constants.paths.servicesPage}/:serviceId/graf`}
          element={<ServiceChartPage />}
        />
      </Route>
    )
  }

  const _renderInbox = () => {
    return (
      <Route
        path={Constants.paths.messagesPage}
        element={
          <PowerCustomerGuard>
            <SubPageLayout autoAdjust={false} />
          </PowerCustomerGuard>
        }
      >
        <Route
          index
          element={<InboxPage />}
        />
      </Route>
    )
  }

  const _renderInvoicePages = () => {
    return (
      <Route
        path={Constants.paths.invoicePage}
        element={
          <PowerCustomerGuard>
            <SubPageLayout />
          </PowerCustomerGuard>
        }
      >
        <Route
          index
          element={<CurrentInvoicesPage />}
        />
        <Route
          path={Constants.paths.invoiceHistoryPage}
          element={
            <ApplicationAccountSelectWrapper>
              <InvoiceHistoryPage />
            </ApplicationAccountSelectWrapper>
          }
        />
        <Route
          path={`${Constants.paths.invoiceHistoryPage}/:invoiceId`}
          element={<InvoiceSelectedPage />}
        />
        <Route
          path={`${Constants.paths.fixedDueDate}/:fixedDueDateStatus/:accountId`}
          element={<InvoiceFixedDueDatePage />}
        />
        <Route
          path={`${Constants.paths.invoicePage}${Constants.paths.invoiceSettings}`}
          element={
            <ApplicationAccountSelectWrapper>
              <InvoiceSettingsPage />
            </ApplicationAccountSelectWrapper>
          }
        />
      </Route>
    )
  }

  const _renderAgreementPage = () => {
    return (
      <Route
        path={Constants.paths.powerAgreementPage}
        element={
          <PowerCustomerGuard>
            <SubPageLayout />
          </PowerCustomerGuard>
        }
      >
        <Route
          index
          element={<EnergyAgreementPage />}
        />
        <Route
          path={`${Constants.paths.powerAgreementPage}/:meterId`}
          element={<EnergyAgreementPage />}
        />
      </Route>
    )
  }

  const _renderBenefitsPages = () => {
    return (
      <Route
        path={Constants.paths.benefitsPage}
        element={
          <PowerCustomerGuard>
            <BenefitsPage />
          </PowerCustomerGuard>
        }
      >
        <Route
          index
          element={<BenefitsOverviewPage />}
        />
        <Route
          path={Constants.paths.benefitsIntroPage}
          element={<BenefitsIntroductionPage />}
        />
        <Route
          path={Constants.paths.myBenefitsPage}
          element={<MyBenefitsPage />}
        />
        <Route
          path={Constants.paths.kickbackTransactionsPage}
          element={<TransactionsPage />}
        />
        <Route
          path={Constants.paths.usePointsPage}
          element={<UsePointsPage />}
        />
        <Route
          path={Constants.paths.usePointsInvoicePage}
          element={<UsePointsInvoicePage />}
        />
        <Route
          path={Constants.paths.usePointsWebshopPage}
          element={<UsePointsWebshopPage />}
        />
      </Route>
    )
  }

  const _renderConsumptionPages = () => {
    return (
      <Route
        path={Constants.paths.powerPage}
        element={
          <PowerCustomerGuard>
            <EnergyConsumptionPage />
          </PowerCustomerGuard>
        }
      >
        <Route
          path={`${Constants.paths.powerCostPage}`}
          element={<EnergyConsumptionCostPage />}
        />
        <Route
          path={`${Constants.paths.powerUsagePage}`}
          element={<EnergyConsumptionUsePage />}
        />
        <Route
          path={`${Constants.paths.powerCostPage}/:meterId`}
          element={<EnergyConsumptionCostPage />}
        />
        <Route
          path={`${Constants.paths.powerUsagePage}/:meterId`}
          element={<EnergyConsumptionUsePage />}
        />
      </Route>
    )
  }

  const _renderSpotpricePage = () => {
    return (
      <Route
        path={Constants.paths.spotPricePage}
        element={
          <PowerCustomerGuard>
            <SubPageLayout autoAdjust={false} />
          </PowerCustomerGuard>
        }
      >
        <Route
          index
          element={<SpotPricePage />}
        />
        <Route
          path={`${Constants.paths.spotPricePage}/:meterId`}
          element={<SpotPricePage />}
        />
      </Route>
    )
  }

  const _renderMovePage = () => {
    return (
      <Route
        path={Constants.paths.movePage}
        element={
          <PowerCustomerGuard>
            <SubPageLayout />
          </PowerCustomerGuard>
        }
      >
        <Route
          index
          element={<MoveOverviewPage />}
        />
        <Route
          path={Constants.paths.moveOutPage}
          element={<MoveOutPage />}
        />
      </Route>
    )
  }

  const _renderMyPage = () => {
    return (
      <Route
        path={Constants.paths.userPage}
        element={
          <PowerCustomerGuard>
            <SubPageLayout />
          </PowerCustomerGuard>
        }
      >
        <Route
          index
          element={<MyPageHome />}
        />
        <Route
          path={`${Constants.paths.userResidences}`}
          element={<ResidencesPage />}
        />
        <Route
          path={`${Constants.paths.userPage}${Constants.paths.invoiceSettings}`}
          element={
            <ApplicationAccountSelectWrapper>
              <InvoiceSettingsPage />
            </ApplicationAccountSelectWrapper>
          }
        />
        <Route
          path={Constants.paths.userCommunication}
          element={<MyCommunicationSettingsPage />}
        />
        <Route
          path={`${Constants.paths.userPage}${Constants.paths.guests}`}
          element={<GuestAdminPage />}
        />
        <Route
          path={Constants.paths.consentPage}
          element={<ConsentPage />}
        />
        <Route
          path={Constants.paths.reservationPage}
          element={<ReservationPage />}
        />
      </Route>
    )
  }

  const _renderOnboardingPage = () => {
    return (
      <Route
        path={`${Constants.paths.onboardingPage}`}
        element={<OnboardingPage />}
      />
    )
  }

  const _renderNotFound = () => {
    return (
      <Route
        path='*'
        element={<NotFoundPage />}
      />
    )
  }

  // ************************************
  // Render
  // ************************************

  return (
    <ApplicationContext.Provider value={contextHandler}>
      <ApplicationOverlayWrapper>
        <ApplicationGuestsAndHostsWrapper>
          <ApplicationServiceWrapper>
            <ApplicationCoreDataWrapper>
              <BrowserRouter>
                <ApplicationDefaultContextWrapper>
                  <ApplicationConsentWrapper>
                    <ApplicationAddonServicesWrapper>
                      <KindlyWrapper>
                        <Routes>
                          <Route
                            path='/'
                            element={<DefaultPageLayout />}
                          >
                            {_renderHomePage()}
                            {_renderInbox()}
                            {_renderInvoicePages()}
                            {_renderAgreementPage()}
                            {_renderBenefitsPages()}
                            {_renderConsumptionPages()}
                            {_renderSpotpricePage()}
                            {_renderMovePage()}
                            {_renderServices()}
                            {_renderMyPage()}
                            {_renderOnboardingPage()}
                            {_renderNotFound()}
                          </Route>
                        </Routes>
                      </KindlyWrapper>
                    </ApplicationAddonServicesWrapper>
                  </ApplicationConsentWrapper>
                </ApplicationDefaultContextWrapper>
              </BrowserRouter>
            </ApplicationCoreDataWrapper>
          </ApplicationServiceWrapper>
        </ApplicationGuestsAndHostsWrapper>
      </ApplicationOverlayWrapper>
    </ApplicationContext.Provider>
  )
}

export default App