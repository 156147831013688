import { ContentGrid, getCounterTheme, Theme } from '@fjordkraft/fjordkraft.component.library'
import { hasText } from '../../../services'
import { MSRichText, ServicePointsCard } from '../../../components'
import React, { useMemo } from 'react'
import { IServicePopupCheckbox } from '../../../models'
import { ContentText } from './ContentText'
import { PlankHouseBlock } from '../../../blocks'
import { ContentCheckbox } from './ContentCheckbox'
import { ContentLinks } from './ContentLinks'
import { IPopupContentData } from './PopupContent'

interface IInformationStep {
  classPrefix: string
  theme: Theme
  brand?: string
  checkboxStates?: IServicePopupCheckbox[]
  setCheckboxStates: (value: IServicePopupCheckbox[]) => void
  data: IPopupContentData
}
export const InformationStep = (props: IInformationStep) => {
  const { checkboxStates, setCheckboxStates, classPrefix, theme, brand, data } = props
  const { dataPoints, texts, house, selectedExtraProductDesc, description, links } = data

  const onCheckboxChange = (checkBox: IServicePopupCheckbox) => {
    let boxes = checkboxStates?.slice() ?? []
    const checkBoxIndex = boxes.findIndex(box => box.id === checkBox.id)
    boxes[checkBoxIndex] = checkBox
    setCheckboxStates(boxes)
  }

  const getCheckboxes = useMemo(() => {
    if (checkboxStates && checkboxStates.length > 0) {
      return checkboxStates.map((checkbox: IServicePopupCheckbox, index) => {
        if (checkbox.useCheckbox) {
          return (
            <ContentCheckbox
              key={checkbox.id}
              classPrefix={classPrefix}
              brand={brand}
              theme={theme}
              checkbox={checkbox}
              onCheckboxChange={onCheckboxChange}
            />
          )
        }
      })
    }
  }, [checkboxStates])

  const getLinks = useMemo(() => {
    if (links && links.length > 0) {
      return (
        <ContentLinks
          classPrefix={classPrefix}
          links={links}
          theme={theme}
          brand={brand}
        />
      )
    }
  }, [])

  return (
    <ContentGrid
      className={`${classPrefix}__content`}
      tagType='section'
      direction='column'
      alignment='top-left'
      gap={2}
      boxSizing='border-box'
    >
      {hasText('subTitle', texts) && (
        <ContentText
          textKey={'subTitle'}
          tag={'h3'}
          texts={texts}
        />
      )}
      {hasText('introduction', texts) && (
        <ContentText
          textKey={'introduction'}
          texts={texts}
        />
      )}
      {hasText('main', texts) && (
        <ContentText
          textKey={'main'}
          texts={texts}
        />
      )}
      {dataPoints && dataPoints.length > 0 && (
        <ServicePointsCard
          className={`${classPrefix}__content__points`}
          points={dataPoints}
          theme={theme}
          brand={brand}
        />
      )}
      {house && (
        <>
          <PlankHouseBlock
            {...house}
            theme={theme}
            brand={brand}
          />
        </>
      )}
      {selectedExtraProductDesc && (
        <MSRichText
          className={`${classPrefix}__content__desc`}
          brand={brand}
          theme={getCounterTheme(theme)}
          text={selectedExtraProductDesc}
          alignment='lawful'
        />
      )}
      {description && (
        <MSRichText
          className={`${classPrefix}__content__desc`}
          brand={brand}
          theme={getCounterTheme(theme)}
          text={description}
          alignment='lawful'
        />
      )}
      {getCheckboxes}
      {getLinks}
    </ContentGrid>
  )
}