import React from 'react'
import App from './App'
import TagManager from 'react-gtm-module'
import WebFont from 'webfontloader'
import AuthenticationGuard from './authentication/AuthenticationGuard'
import { AuthProvider } from 'react-oidc-context'
import { authConfig } from './authentication/authConfig'
import { createRoot } from 'react-dom/client'
import './styling/styling.scss'
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

initializeNewRelic()

//Remove logged in status if we receive a login parameter
if (new URL(window.location.href).searchParams.get('li')) {
  Object.keys(window.localStorage)
    .filter(k => k.startsWith('oidc.user:'))
    .forEach(k => window.localStorage.removeItem(k))
}

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700']
  }
})

const tagManagerArgs = {
  gtmId: import.meta.env.REACT_APP_ANALYTICS_GTM ?? 'No GTM in config'
}
TagManager.initialize(tagManagerArgs)

const domNode = document.getElementById('root')

if (domNode) {
  const root = createRoot(domNode)

  root.render(
    <React.StrictMode>
      <AuthProvider
        {...authConfig}
        automaticSilentRenew={false}
      >
        <AuthenticationGuard>
          <App />
        </AuthenticationGuard>
      </AuthProvider>
    </React.StrictMode>
  )
}

function initializeNewRelic() {
  const options = {
    init: {
      session_replay: {
        enabled: true,
        block_selector: '',
        mask_text_selector: '',
        sampling_rate: 1.0,
        error_sampling_rate: 100.0,
        mask_all_inputs: false,
        collect_fonts: true,
        inline_images: false,
        inline_stylesheet: true,
        mask_input_options: {}
      },
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] }
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: import.meta.env.REACT_APP_NEWRELIC_LICENSEKEY,
      applicationID: import.meta.env.REACT_APP_NEWRELIC_APPLICATIONID,
      sa: 1
    },
    loader_config: {
      accountID: import.meta.env.REACT_APP_NEWRELIC_ACCOUNTID,
      trustKey: import.meta.env.REACT_APP_NEWRELIC_TRUSTKEY,
      agentID: import.meta.env.REACT_APP_NEWRELIC_AGENTID,
      licenseKey: import.meta.env.REACT_APP_NEWRELIC_LICENSEKEY,
      applicationID: import.meta.env.REACT_APP_NEWRELIC_APPLICATIONID
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let agent = new BrowserAgent(options)
}