import { Text, TextType } from '@fjordkraft/fjordkraft.component.library'
import { h3TextPrefab, paragraphTextPrefab } from '../../../Prefabs'
import { hasText } from '../../../services'
import React from 'react'
import { ITranslationItem } from '../../../models'

export const ContentText = ({
  textKey,
  tag = 'p',
  texts
}: {
  textKey: string
  tag?: TextType
  texts: ITranslationItem[]
}) => {
  let prefab = tag === 'p' ? paragraphTextPrefab() : h3TextPrefab()

  return <Text {...prefab}>{hasText(textKey, texts)}</Text>
}
