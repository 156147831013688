import React from 'react'
import {
  Alignment,
  BaseComponent,
  ContentGrid,
  IAction,
  IComponent,
  IModalTemplate,
  StyleGrid,
  StyleLoopLimit
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { ButtonTypes, PopupModalTemplate } from '../../Prefabs'
import { useApplicationContext } from '../../contexts'
import './PopupCard.scss'
import { ContentHeader } from './components/ContentHeader'
import { Header } from './components/Header'
import { ContentCoreNav, INavigation } from './components/ContentCoreNav'

export interface IPopupCard extends IComponent {
  contentTitle?: string
  contentSubTitle?: string
  contentSubTitleAlignment?: 'align-left' | 'align-center' | 'align-right'
  contentTitleAlignment?: 'align-left' | 'align-center' | 'align-right'
  contentAlignment?: Alignment
  contentGap?: StyleLoopLimit
  template?: IModalTemplate
  primaryActionStyle?: ButtonTypes
  secondaryActionStyle?: ButtonTypes
  onClose?: () => void
  showCloseButton?: boolean
  primaryAction?: IAction
  secondaryAction?: IAction
  desktopView?: boolean
  navigationContent?: any
}

export const PopupCard = (props: IPopupCard) => {
  // ************************************
  // Properties
  // ************************************

  const { desktopView } = useApplicationContext()
  const {
    theme = 'Light',
    brand,
    onClose,
    showCloseButton = true,
    children,
    className,
    navigationContent,
    contentTitle,
    contentTitleAlignment = 'align-left',
    contentSubTitle,
    contentSubTitleAlignment = 'align-left',
    contentAlignment,
    contentGap = 0,
    primaryActionStyle = 'primary',
    secondaryActionStyle = 'secondary',
    primaryAction,
    secondaryAction,
    template = PopupModalTemplate(theme, desktopView)
  } = props
  const classPrefix = 'ms-popup-card'

  // ************************************
  // Render
  // ************************************

  const navigationProps: INavigation = {
    navigationContent,
    secondaryAction,
    secondaryActionStyle,
    primaryAction,
    primaryActionStyle
  }

  return (
    <StyleGrid
      className={classNames(`${classPrefix}`, {
        [`${classPrefix}--${theme}`]: theme,
        [`${className}`]: className
      })}
      direction='column'
      alignment={desktopView ? 'center' : 'bottom-center'}
      boxSizing='border-box'
    >
      <BaseComponent
        className={`${classPrefix}__card`}
        brand={brand}
        theme={theme}
        template={template}
      >
        {onClose && showCloseButton && (
          <Header
            classPrefix={classPrefix}
            brand={brand}
            onClose={onClose}
            theme={theme}
          />
        )}
        <StyleGrid
          id={`${classPrefix}__card__content-wrapper`}
          className={`${classPrefix}__card__content-wrapper`}
          direction='column'
          alignment={contentTitle ? 'top-left' : 'top-center'}
          gap={contentTitle ? 2 : 0}
          boxSizing='border-box'
          wrap={false}
        >
          {(contentTitle || contentSubTitle) && (
            <ContentHeader
              classPrefix={classPrefix}
              theme={theme}
              content={{ contentTitle, contentTitleAlignment, contentSubTitle, contentSubTitleAlignment }}
            />
          )}
          <ContentGrid
            className={`${classPrefix}__card__content`}
            direction='column'
            alignment={(contentAlignment ?? contentTitle) ? 'top-left' : 'top-center'}
            gap={contentGap}
            boxSizing='border-box'
            tagType='section'
          >
            {children}
          </ContentGrid>
          {!desktopView && (secondaryAction || primaryAction) && (
            <ContentCoreNav
              classPrefix={classPrefix}
              brand={brand}
              desktopView={desktopView}
              theme={theme}
              navigation={navigationProps}
            />
          )}
        </StyleGrid>
        {desktopView && (secondaryAction || primaryAction) && (
          <ContentCoreNav
            classPrefix={classPrefix}
            brand={brand}
            desktopView={desktopView}
            theme={theme}
            navigation={navigationProps}
          />
        )}
      </BaseComponent>
    </StyleGrid>
  )
}
