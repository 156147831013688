import { IconType, Theme } from '@fjordkraft/fjordkraft.component.library'
import {
  getPlankPrefab,
  IPointPlankPrefab,
  ITextPlankPrefab,
  MS_ButtonTemplate,
  TrumfPlankTemplate
} from '../../../../../Prefabs'
import {
  IAddonData,
  ICustomerInstallation,
  IRelationship,
  IServicePage,
  IServices,
  IUser,
  ServiceOrderSteps,
  ServiceOrderStepsEnum,
  ServiceStatus,
  ServiceStatusEnum
} from '../../../../../models'
import {
  createString,
  getServiceStatusColor,
  getSpecificAddonStateForInstallation,
  getText,
  IResponse,
  mapServiceStatusToOrderResult,
  narrowDownPopupState,
  narrowDownResultStatusForPlank
} from '../../../../../services'
import { format } from 'date-fns'
import { IStatePlank } from '../../../../../components'
import { IServiceOrderOrCancel } from '../../../../../modals'
import { IMSPlankWall, IPlankHouse } from '../../../../../blocks'

export interface ITrumfTransactions {
  sumPoints: number
  periodStart: string
  periodEnd: string
  monthly: ITrumfMonth[]
  transactions: ITrumfTransaction[]
}

export interface ITrumfMonth {
  sumPoints: number
  accumulatedPointsForYear: number
  year: number
  month: number
}

export interface ITrumfTransaction {
  id: string
  trumfId: string
  points: number
  date: string
}

export interface IgetTrumfPageHouseData {
  onClickService: (value: IServiceOrderOrCancel) => void
  addonStates: IAddonData[]

  services: IServices
  user: IUser
  relationship: IRelationship
  translations: any
  activeTheme: Theme
  desktopView: boolean
}

export const getTrumfHouse = async (config: IgetTrumfPageHouseData): Promise<IPlankHouse> => {
  const walls = await _getTrumfPlankWalls(config)
  return {
    plankWalls: [...(walls ?? [])]
  }
}

const _getTrumfPlankWalls = async (config: IgetTrumfPageHouseData): Promise<IMSPlankWall[]> => {
  let walls: IMSPlankWall[] = []

  let detailPlanks: IStatePlank[] | undefined = await _getTrumfDetailPlanks(config.services, config.translations)
  let orderAndCancelPlanks: IStatePlank[] = _getOrderAndCancelPlanks(config)

  if (detailPlanks) walls.push({ planks: detailPlanks })

  return walls.concat({ planks: orderAndCancelPlanks })
}

const _getOrderAndCancelPlanks = (config: IgetTrumfPageHouseData): IStatePlank[] => {
  const { user, relationship, translations, onClickService, activeTheme, desktopView, addonStates } = config
  const { userData } = user
  const { isGuest } = relationship

  let statusData = getCustomerInstallationStatusData(user, translations, addonStates)
  let title: string = `${userData.firstName} ${userData.lastName}`
  let description: string = createString(getText('customerNumber', translations), {
    customerNumber: userData.customerId
  })

  const actionPlank = getPlankPrefab('Action', {
    action: {
      text: desktopView ? getText('configure', translations) : undefined,
      icon: IconType.Edit,
      disabled: !statusData.allowOrder || isGuest,
      onClick: () => {
        onClickService({
          status: statusData.status,
          userData,
          page: translations
        } as IServiceOrderOrCancel)
      }
    },
    actionButtonPadding: desktopView ? 'small' : 'default',
    actionIconPlacement: 'Right',
    left: {
      title,
      description
    },
    right: {
      template: MS_ButtonTemplate(activeTheme, 'primary')
    }
  })

  const textPlank = getPlankPrefab('Text', {
    left: {
      title,
      description
    },
    right: translations.allowOrder && {
      title: undefined,
      customization: {
        title: {
          color: getServiceStatusColor(statusData.status)
        }
      }
    }
  } as ITextPlankPrefab)

  const editTrumfPlank = _showActionButton(statusData.status, translations) ? actionPlank : textPlank
  const installationStatusPlanks = _getInstallationStatusPlanks(statusData.installationStatuses)

  return [editTrumfPlank, ...installationStatusPlanks]
}

interface IInstallationStatus {
  status: ServiceStatus
  text: string
  streetAddress: string
}

export const getCustomerInstallationStatusData = (user: IUser, translations: any, addonStates: IAddonData[]) => {
  const { userData } = user

  let installationStatuses: IInstallationStatus[] = []

  if (userData.installations && userData.installations.length > 0) {
    installationStatuses = userData.installations.map((installation: ICustomerInstallation) => {
      const status = narrowDownResultStatusForPlank(
        getSpecificAddonStateForInstallation(translations.productDefinitionId, installation.meterId, addonStates)
          ?.state ?? ServiceStatusEnum.INACTIVE
      )

      return {
        text: _getInstallationStatusText(status, translations),
        status: status,
        streetAddress: installation.address.streetAddress
      }
    })
  }

  let status: ServiceStatus = installationStatuses.find(
    instStatus => mapServiceStatusToOrderResult(instStatus.status) === ServiceOrderStepsEnum.FAILURE
  )
    ? ServiceStatusEnum.INACTIVE
    : ServiceStatusEnum.ACTIVE

  let allowOrder = _allowOrderTrumfBasedOnInstallationStatuses(installationStatuses)

  return { allowOrder, status, installationStatuses }
}

const _allowOrderTrumfBasedOnInstallationStatuses = (installationStatuses: IInstallationStatus[]): boolean => {
  if (installationStatuses && installationStatuses.length > 0) {
    for (let installationStatus of installationStatuses) {
      let statusAsStep: ServiceOrderSteps = narrowDownPopupState(installationStatus.status)

      if (statusAsStep === ServiceOrderStepsEnum.IN_PROGRESS) {
        return false
      }
    }
  }

  return true
}

const _getInstallationStatusText = (status: ServiceStatus, transactions: IServicePage) => {
  if (status === ServiceStatusEnum.ACTIVE) {
    return getText('plankActivationStatusActive', transactions)
  } else if (status === ServiceStatusEnum.INACTIVE) {
    return getText('plankActivationStatusNotActive', transactions)
  } else {
    return getText('plankActivationStatusInProgress', transactions)
  }
}

const _getInstallationStatusPlanks = (statuses: IInstallationStatus[]): IStatePlank[] => {
  let planks: IStatePlank[] = []

  statuses.forEach((status: IInstallationStatus) => {
    planks.push(
      getPlankPrefab('Text', {
        left: {
          title: status.streetAddress
        },
        right: {
          title: status.text,
          customization: {
            title: {
              color: getServiceStatusColor(status.status)
            }
          }
        }
      })
    )
  })

  return planks
}

const _getTrumfDetailPlanks = async (services: IServices, translations: any) => {
  const { GET } = services

  let resp: IResponse = await GET('Trumf/transactions')

  if (resp.callState === 'success' && resp.data) {
    let trumfData: ITrumfTransactions = resp.data

    return [
      getPlankPrefab('Point', {
        brand: 'brand-trumf',
        theme: 'Dark',
        template: TrumfPlankTemplate('Dark', 'top'),
        useDecimals: true,
        left: {
          title: getText('plankOverviewTitle', translations),
          description: `${format(new Date(trumfData.periodStart), 'dd.MM.yyyy')} - ${format(
            new Date(trumfData.periodEnd),
            'dd.MM.yyyy'
          )}`,
          dontUseCounterTheme: true
        },
        points: trumfData.sumPoints,
        pointsLabel: getText('currency', translations)
      } as IPointPlankPrefab),
      getPlankPrefab('Text', {
        left: {
          title: getText('plankTransactionsTitle', translations),
          description: getText('plankTransactionsDesc', translations)
        },
        right: {
          icon: IconType.ChevronRight
        },
        action: {
          link: 'transaksjoner',
          useRouterLink: true
        }
      })
    ] as IStatePlank[]
  }
}

const _showActionButton = (status: ServiceStatus, servicePage: IServicePage): boolean => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.ACTIVE_FUTURE:
      return true
    case ServiceStatusEnum.INACTIVE:
    case ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER:
    case ServiceStatusEnum.TERMINATED:
    case ServiceStatusEnum.ORDER_FAILED:
      return servicePage.allowOrder ?? true
  }
  return false
}