import _ from 'lodash'
import { getEpiServicePage } from '../services'
import { PageType } from '../models'

export const getTranslations = (epiChildren: any, pageType: PageType, translations: any, subPageType?: string) => {
  if (!subPageType && _.isEqual(epiChildren[pageType].data, translations)) {
    return translations
  }
  if (subPageType) {
    return getEpiServicePage(epiChildren[pageType], subPageType)
  }
  return epiChildren[pageType].data
}
