import { ContentGrid, IAction, LinkButtonTemplate, Theme } from '@fjordkraft/fjordkraft.component.library'
import { ActionButton } from '../../../components'
import { v4 as uuid4 } from 'uuid'
import React from 'react'

export const ContentLinks = ({
  classPrefix,
  links,
  theme,
  brand
}: {
  classPrefix: string
  links: IAction[]
  theme: Theme
  brand?: string
}) => {
  return (
    <ContentGrid
      className={`${classPrefix}__links`}
      tagType='nav'
      alignment='top-left'
      gap={2}
      direction='column'
    >
      {links.map((action: any) => {
        return (
          <ActionButton
            key={uuid4()}
            action={{
              ...action,
              ...{ link: action.url }
            }}
            template={LinkButtonTemplate(theme)}
            brand={brand}
          />
        )
      })}
    </ContentGrid>
  )
}
