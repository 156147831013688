import { IconType } from '@fjordkraft/fjordkraft.component.library'
import { _getTextPlankPrefabTemplate } from '.'
import { Constants } from '../../../data'
import { AllowFeature, getText } from '../../../services'
import { IHomePageData } from '../HomePageData'
import { IStatePlank } from '../../../components'

export const MySettingsPlank = (props: IHomePageData) => {
  const { translations, services } = props
  const { user, customerServiceFeature } = services

  if (AllowFeature(Constants.features.mySettings, translations, user, customerServiceFeature)) {
    return {
      promise: fetchSettingsPlank(props)
    } as IStatePlank
  }
}

const fetchSettingsPlank = async (props: IHomePageData) => {
  let { activeTheme, activeBrand, translations } = props

  return _getTextPlankPrefabTemplate({
    theme: activeTheme,
    brand: activeBrand,
    title: getText('plankSettingsTitle', translations),
    description: getText('plankSettingsDesc', translations),
    link: Constants.paths.userPage,
    iconLeft: IconType.CogLowDetail,
    iconRight: IconType.ChevronRight
  })
}