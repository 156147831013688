import { IDescriptionBlock } from '../../../../blocks'
import {
  IServicePage,
  IServiceStatusDescriptions,
  ServiceStatus,
  ServiceStatusDescriptionEnum,
  ServiceStatusEnum
} from '../../../../models'

export interface IServicePageDataDescriptions {
  top?: IDescriptionBlock[]
  middle?: IDescriptionBlock[]
  bottom?: IDescriptionBlock[]
}

// ************************************
// PUBLIC
// ************************************

export const getServicePageDataDescriptions = (
  servicePage: IServicePage,
  status?: ServiceStatus
): IServicePageDataDescriptions | undefined => {
  let stateDescription: IServiceStatusDescriptions | undefined = getServiceEpiDataBasedOnStatus(servicePage, status)

  if (stateDescription) {
    let top: IDescriptionBlock[] = []
    let middle: IDescriptionBlock[] = []
    let bottom: IDescriptionBlock[] = []

    if (stateDescription.infoBlocks?.length > 0) {
      stateDescription.infoBlocks.forEach((info: IDescriptionBlock) => {
        switch (info.placement) {
          case 'top-center':
          case 'top-left':
          case 'top-right':
            top.push(info)
            break
          case 'center':
          case 'center-left':
          case 'center-right':
            middle.push(info)
            break
          case 'bottom-center':
          case 'bottom-left':
          case 'bottom-right':
            bottom.push(info)
            break
        }
      })
    }

    return { top, middle, bottom }
  }
}

export const getServiceEpiDataBasedOnStatus = (
  servicePage: IServicePage,
  status?: ServiceStatus
): IServiceStatusDescriptions | undefined => {
  if (servicePage.serviceDescriptions?.length > 0) {
    if (status) {
      const foundDesc = servicePage.serviceDescriptions.find(desc => _pointOutStateDescription(desc, status))
      if (foundDesc) return foundDesc
    }
    return servicePage.serviceDescriptions[0]
  }
}

// ************************************
// PRIVATE
// ************************************

const _pointOutStateDescription = (desc: IServiceStatusDescriptions, status: ServiceStatus) => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.TERMINATING:
    case ServiceStatusEnum.ACTIVE_FUTURE:
      if (desc.status === ServiceStatusDescriptionEnum.SUBSCRIBED) return desc
      break
    case ServiceStatusEnum.INACTIVE:
    case ServiceStatusEnum.TERMINATED:
    case ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER:
      if (desc.status === ServiceStatusDescriptionEnum.UNSUBSCRIBED) return desc
      break
    case ServiceStatusEnum.ACTIVATING:
    case ServiceStatusEnum.ORDER_IN_PROGRESS:
    case ServiceStatusEnum.ORDER_WAITING_FOR_CUSTOMER:
      if (desc.status === ServiceStatusDescriptionEnum.PENDING) return desc
      break
  }
}
