import { IconType } from '@fjordkraft/fjordkraft.component.library'
import { IMSPlankWall, IPlankHouse } from '../../../../blocks'
import { IServiceBasePageData } from '../ServiceBasePageData'
import { IStatePlank } from '../../../../components'
import { IAddonData, ICustomerInstallation, IServicePage, ServiceStatus } from '../../../../models'
import { getPlankPrefab, ITextPlankPrefab, MS_ButtonTemplate } from '../../../../Prefabs'
import { IActionPlankPrefab } from '../../../../Prefabs/Planks/ActionPlankPrefab/ActionPlankPrefab'
import {
  createString,
  getServiceStatusColor,
  getSpecificAddonStateForInstallation,
  getText,
  showingDropdownOrderWrapper
} from '../../../../services'

// ************************************
// PUBLIC
// ************************************

export const getBaseServicePageHouseData = (
  translations: any,
  desktopView: boolean,
  onClickServiceHandling: ({
    installation,
    status,
    page,
    addonStateResponse
  }: {
    installation: ICustomerInstallation
    status: ServiceStatus
    page: any
    addonStateResponse: IAddonData[] | undefined
  }) => void,
  isGuest: boolean,
  addonStates?: IAddonData[],
  installation?: ICustomerInstallation
): IPlankHouse | undefined => {
  return {
    plankWalls: _getInstallationWalls(
      translations,
      desktopView,
      onClickServiceHandling,
      isGuest,
      addonStates,
      installation
    )
  }
}

export const getServicePageHouseData = async (config: IServiceBasePageData): Promise<IPlankHouse | undefined> => {
  return {
    plankWalls: _getInstallationWallsWrapper(config)
  }
}

interface IServiceActionPlank {
  title: string
  desc: string
  servicePage: IServicePage
  status: ServiceStatus
  desktopView: boolean
  isGuest: boolean
  onClick: (value: any) => void
  bottom?: IActionPlankPrefab['bottom']
}

export const getActionPlank = (config: IServiceActionPlank): IStatePlank => {
  const { title, desc, servicePage, status, onClick, isGuest, bottom } = config

  let statusValues = _getActionPlankStatusValues(status, servicePage)
  let active: boolean = status === 'ACTIVE' || status === 'ACTIVE_FUTURE'

  if (statusValues.showButton) {
    return getPlankPrefab('Action', {
      action: {
        disabled: isGuest,
        onClick,
        text: statusValues.value,
        icon: IconType.ExternalLinkThick
      },
      actionButtonPadding: 'small',
      left: {
        title,
        description: desc
      },
      right: {
        template: MS_ButtonTemplate('Light', active ? 'secondary' : 'primary')
      },
      bottom: bottom,
      actionIconPlacement: 'Right'
    } as IActionPlankPrefab)
  } else {
    return getPlankPrefab('Text', {
      left: {
        title,
        description: desc
      },
      right: servicePage.allowOrder
        ? {
            title: statusValues.value,
            customization: {
              title: {
                color: getServiceStatusColor(status)
              }
            }
          }
        : undefined,
      bottom: bottom
    } as ITextPlankPrefab)
  }
}

// ************************************
// PRIVATE :: Walls
// ************************************

const _getInstallationWalls = (
  translations: any,
  desktopView: boolean,
  onClickServiceHandling: ({
    installation,
    status,
    page,
    addonStateResponse
  }: {
    installation: ICustomerInstallation
    status: ServiceStatus
    page: any
    addonStateResponse: IAddonData[] | undefined
  }) => void,
  isGuest: boolean,
  addonStates?: IAddonData[],
  installation?: ICustomerInstallation
) => {
  let walls: IMSPlankWall[] = []
  let planks: IStatePlank[] | undefined = _getInstallationPlanks(
    translations,
    desktopView,
    onClickServiceHandling,
    isGuest,
    addonStates,
    installation
  )

  if (installation && planks && planks.length > 0) {
    walls.push({ planks })
  }

  return walls
}

const _getInstallationWallsWrapper = (config: IServiceBasePageData): IMSPlankWall[] => {
  const { translations, addonStates, desktopView, onClickServiceHandling, user, relationship } = config
  const { installation } = user
  const { isGuest } = relationship

  return _getInstallationWalls(translations, desktopView, onClickServiceHandling, isGuest, addonStates, installation)
}

// ************************************
// PRIVATE :: Planks
// ************************************

const _getInstallationPlanks = (
  translations: any,
  desktopView: boolean,
  onClickServiceHandling: ({
    installation,
    status,
    page,
    addonStateResponse
  }: {
    installation: ICustomerInstallation
    status: ServiceStatus
    page: any
    addonStateResponse: IAddonData[] | undefined
  }) => void,
  isGuest: boolean,
  addonStates?: IAddonData[],
  installation?: ICustomerInstallation
): IStatePlank[] | undefined => {
  if (installation) {
    let planks: IStatePlank[] = []
    let status: ServiceStatus =
      getSpecificAddonStateForInstallation(translations.productDefinitionId, installation.meterId, addonStates)
        ?.state ?? 'INACTIVE'
    let title: string = installation.address.streetAddress ?? ''
    let desc: string = createString(getText('plankInstallationDesc', translations), {
      meterId: installation.meterId
    })

    if (!showingDropdownOrderWrapper(translations, status)) {
      planks.push(
        getActionPlank({
          status,
          title,
          desc,
          servicePage: translations,
          desktopView,
          isGuest,
          onClick: () => {
            onClickServiceHandling({
              installation,
              status,
              page: translations,
              addonStateResponse: addonStates
            })
          }
        })
      )
    }

    if (planks.length > 0) {
      return planks
    }
  }
}

// ************************************
// PRIVATE :: Handling
// ************************************

const _getActionPlankStatusValues = (status: ServiceStatus, servicePage: IServicePage) => {
  let showButton: boolean = false
  let value: string = ''

  switch (status) {
    case 'ACTIVE':
    case 'ACTIVE_FUTURE':
      value = getText('cancel', servicePage)
      showButton = true
      break
    case 'INACTIVE':
    case 'ORDER_CANCELLED_BY_CUSTOMER':
    case 'TERMINATED':
    case 'ORDER_FAILED':
      value = getText('order', servicePage)
      showButton = servicePage.allowOrder ?? true
      break
    case 'ACTIVATING':
    case 'TERMINATING':
    case 'ORDER_IN_PROGRESS':
    case 'ORDER_WAITING_FOR_CUSTOMER':
      value = getText('awaiting', servicePage)
      break
  }

  return { showButton, value }
}