import { ContentGrid, IconType, Theme } from '@fjordkraft/fjordkraft.component.library'
import { ActionButton } from '../../../components'
import { MS_ButtonTemplate } from '../../../Prefabs'
import React from 'react'

export const Header = ({
  classPrefix,
  brand,
  onClose,
  theme
}: {
  classPrefix: string
  brand?: string
  onClose: () => void
  theme: Theme
}) => {
  return (
    <ContentGrid
      className={`${classPrefix}__card__nav`}
      tagType='nav'
      alignment='center-right'
      direction='row'
      boxSizing='border-box'
    >
      <ActionButton
        brand={brand}
        action={{
          onClick: () => {
            onClose()
          },
          icon: IconType.Close
        }}
        template={MS_ButtonTemplate(theme, 'icon')}
        iconPlacement='Right'
      />
    </ContentGrid>
  )
}
