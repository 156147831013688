import React from 'react'
import {
  BrandColors,
  ContentGrid,
  IAction,
  IconType,
  IRadioSelectItem,
  Text
} from '@fjordkraft/fjordkraft.component.library'
import {
  getPlankPrefab,
  ITextPlankPrefab,
  LinkButtonTemplate,
  MS_ButtonTemplate,
  MSCheckInputTemplate,
  paragraphTextPrefab,
  TypedPlank
} from '../../../Prefabs'
import { IMSPlankWall, IPlankHouse } from '../../../blocks'
import { capitalizeFirstLetter, createString, getAddressesBasedOnAgreement, getText } from '../../../services'
import { DirectDebitStatus } from './InvoiceSettingsPage'
import { Constants } from '../../../data'
import { ICustomer, ICustomerAccountInformation, IDefaultProps } from '../../../models'
import { ActionButton } from '../../../components'
import { ICustomerDataToEdit } from '../../DatahandlerWrappers/ApplicationUserEditWrapper/ApplicationUserEditWrapper'
import { getSteddiStatus } from '../../ServicesPagesWrapper/ServicePage/Datahandling/UniqueServices'

export interface IInvoiceSettingsPageDatahandler extends IDefaultProps {
  activeAccount: ICustomerAccountInformation
  location: any
  setShowDirectDebitPopup: (value: boolean) => void
  setCustomerDataToEdit: (data: ICustomerDataToEdit) => void
}

// ************************************
// Public
// ************************************

export const getPageContent = async (config: IInvoiceSettingsPageDatahandler) => {
  const { translations, activeAccount, user, location, setCustomerDataToEdit, services, activeBrand } = config
  const { userData } = user

  if (activeAccount && userData && translations && location?.pathname) {
    let invoiceSettings: IPlankHouse = await _getInvoiceSettings(config)

    // Get delivery method options
    const hasEInvoiceReference = await _getEInvoiceReference(config)

    const deliveryMethodOptions = getDeliveryMethodOptions(
      translations,
      activeAccount,
      hasEInvoiceReference,
      setCustomerDataToEdit,
      activeBrand
    )

    const invoiceAddress = _invoiceAddress(activeAccount, setCustomerDataToEdit, translations)
    const steddiStatus = await getSteddiStatus(userData.accounts, activeAccount?.accountId, services.GETTYPED)

    return {
      ...config,
      sub: {
        title: getText('pageTitle', translations),
        back: _getBack(location.pathname ?? '', translations),
        statusToast: undefined,
        subTitle: undefined
      },
      invoiceSettings,
      deliveryMethodOptions,
      invoiceAddress,
      hasSteddi: steddiStatus === 'ACTIVE' || steddiStatus === 'ACTIVE_FUTURE'
    }
  }
}

export const getInvoiceAgreementOptions = (
  userData: ICustomer,
  translation: any,
  newSelected: ICustomerAccountInformation | undefined,
  onClick: (newSelected: ICustomerAccountInformation) => void
) => {
  let planks: TypedPlank[] = []
  let activePlank: ICustomerAccountInformation = {} as ICustomerAccountInformation

  if (userData.accounts?.length > 0) {
    userData.accounts.forEach((account: ICustomerAccountInformation) => {
      if (account !== newSelected) {
        planks.push({
          type: 'Text',
          data: {
            left: {
              title: createString(getText('plankInvoiceAgreementTitle', translation), {
                number: account.accountId
              }),
              description: getAddressesBasedOnAgreement({ userData, accountId: account.accountId })
            },
            action: {
              onClick: () => {
                onClick(account)
              }
            }
          } as ITextPlankPrefab
        } as TypedPlank)
      }
    })

    activePlank = newSelected ?? userData.accounts[0]
  }

  return { planks, activePlank }
}

// ************************************
// Private
// ************************************

const _getBack = (pathname: string, translations: any) => {
  let split: string[] = pathname.split('/')
  let userPage: string = Constants.paths.userPage.split('/')[1]
  let invoicePage: string = Constants.paths.invoicePage.split('/')[1]

  if (split[1] === userPage) {
    return {
      link: Constants.paths.userPage,
      text: getText(`back${capitalizeFirstLetter(split[1])}`, translations)
    } as IAction
  } else if (split[1] === invoicePage) {
    return {
      link: Constants.paths.invoicePage,
      text: getText(`back${capitalizeFirstLetter(split[1])}`, translations)
    } as IAction
  } else {
    return {
      link: Constants.paths.energyPage,
      text: getText(`back`, translations)
    } as IAction
  }
}

const _getAddressFormatted = (selectedAccount: ICustomerAccountInformation, translation: any) => {
  let address: string = selectedAccount.address.streetAddress ?? getText('unknown', translation)
  let postalCode: string = selectedAccount.address.postalCode ?? ''
  let postalLocation: string = selectedAccount.address.postalLocation ?? ''
  return { address, postalCode, postalLocation }
}

export const getDeliveryMethodOptions = (
  translation: any,
  selectedAccount: ICustomerAccountInformation,
  hasEInvoiceReference: boolean,
  onClick: (data: ICustomerDataToEdit) => void,
  activeBrand: string
) => {
  let options: IRadioSelectItem[] = []
  let method: string = selectedAccount.deliveryMethod

  // Efaktura
  options.push({
    id: 'EINVOICE2',
    title: getText('selectionEInvoiceTitle', translation),
    radioTemplate: MSCheckInputTemplate('Light', 'radio'),
    disabled: !hasEInvoiceReference,
    bottomContent: !hasEInvoiceReference ? _noEInvoiceBottomContent(translation) : undefined
  } as IRadioSelectItem)

  // Papir
  options.push({
    id: 'Paper',
    title: getText('selectionPaperTitle', translation),
    radioTemplate: MSCheckInputTemplate('Light', 'radio'),
    showBottomContentOnlyWhenActive: true
  } as IRadioSelectItem)

  // E-post med SMS-varsling
  if (activeBrand !== 'brand-trondelagkraft') {
    options.push({
      id: 'SMS_PDF',
      title: getText('selectionEmailAndSMSTitle', translation),
      bottomContent: _emailBottomContent(selectedAccount, onClick, translation),
      radioTemplate: MSCheckInputTemplate('Light', 'radio'),
      showBottomContentOnlyWhenActive: true
    } as IRadioSelectItem)
  }

  // E-post
  options.push({
    id: 'PDF',
    title: getText('selectionEmailTitle', translation),
    bottomContent: _emailBottomContent(selectedAccount, onClick, translation),
    radioTemplate: MSCheckInputTemplate('Light', 'radio'),
    showBottomContentOnlyWhenActive: true
  } as IRadioSelectItem)

  let selectedIndex: number = 0

  options.forEach((option: IRadioSelectItem, index: number) => {
    option.active = false

    if (option.id === method) {
      option.active = true
      selectedIndex = index
    }
  })

  return { options, selectedIndex }
}

const _invoiceAddress = (
  selectedAccount: ICustomerAccountInformation,
  onClick: (data: ICustomerDataToEdit) => void,
  translation: any
) => {
  let formattedAddress = _getAddressFormatted(selectedAccount, translation)

  return (
    <ContentGrid
      tagType='section'
      gap={1}
      alignment='top-left'
      direction='column'
    >
      <Text
        {...paragraphTextPrefab()}
        faded
        align='align-left'
      >
        {getText('selectionContentTitle', translation)}
      </Text>
      <Text
        {...paragraphTextPrefab()}
        wrap='pre-line'
        align='align-left'
      >
        {`${formattedAddress.address} \n ${formattedAddress.postalCode} ${formattedAddress.postalLocation}`}
      </Text>
      <ActionButton
        template={LinkButtonTemplate('Light')}
        action={{
          text: getText('changeAddress', translation),
          onClick: () =>
            onClick({
              fields: {
                address: formattedAddress.address,
                postalCode: formattedAddress.postalCode,
                postalLocation: formattedAddress.postalLocation
              },
              topText: getText('updateFormDesc', translation),
              title: getText('updateAddressFormTitle', translation)
            } as ICustomerDataToEdit)
        }}
      />
    </ContentGrid>
  )
}

// bottom content for not available eInvoice
const _noEInvoiceBottomContent = (translation: any) => {
  return <Text {...paragraphTextPrefab()}>{getText('noEInvoiceBottomContent', translation)}</Text>
}

const _emailBottomContent = (
  selectedAccount: ICustomerAccountInformation,
  onClick: (data: ICustomerDataToEdit) => void,
  translation: any
) => {
  return (
    <ContentGrid
      tagType={'section'}
      direction={'column'}
      alignment={'top-left'}
      gap={2}
    >
      <Text
        {...paragraphTextPrefab()}
        faded
      >
        {getText('selectionContentTitle', translation)}
      </Text>
      <Text {...paragraphTextPrefab()}>{selectedAccount.email ?? getText('unknown', translation)}</Text>
      <ActionButton
        template={LinkButtonTemplate('Light')}
        action={{
          text: getText('changeEmail', translation),
          onClick: () => {
            onClick({
              fields: {
                email: selectedAccount.email ?? ''
              },
              topText: getText('updateFormDesc', translation),
              title: getText('updateEmailFormTitle', translation)
            } as ICustomerDataToEdit)
          }
        }}
      />
    </ContentGrid>
  )
}

const _getInvoiceSettings = async (config: IInvoiceSettingsPageDatahandler) => {
  const { user, activeAccount, translations } = config
  const { userData } = user

  let invoiceSettings: IPlankHouse = {
    plankWalls: []
  }

  let selectionPlankWall: IMSPlankWall = {
    planks: []
  }

  // Invoice agreement
  if (userData?.accounts.length > 0) {
    selectionPlankWall.planks.push(
      getPlankPrefab('Text', {
        left: {
          title: createString(getText('plankInvoiceAgreementTitle', translations), {
            number: activeAccount.accountId
          }),
          description: getAddressesBasedOnAgreement({ userData, accountId: userData.accounts[0].accountId })
        }
      })
    )
  }

  // Mobile subscription
  if (userData?.accounts?.length === 1) {
    invoiceSettings.plankWalls.push(selectionPlankWall)
  }

  // Payment Method / Direct debit
  let showDirectDebit: boolean = true

  if (userData?.accounts && userData.accounts.length > 0) {
    userData.accounts.forEach((acc: ICustomerAccountInformation) => {
      if (acc.steddiInfo?.active && acc.accountId === activeAccount.accountId) {
        showDirectDebit = false
      }
    })
  }

  // Direct debit
  if (showDirectDebit) {
    let plankWall: IMSPlankWall = await _getDirectDebitPlankWall(config)
    invoiceSettings.plankWalls.push(plankWall)
  }

  return invoiceSettings
}

const _getEInvoiceReference = async (config: IInvoiceSettingsPageDatahandler): Promise<boolean> => {
  const { services } = config
  const { GETTYPED } = services

  const resp = await GETTYPED<boolean>(`Account/hasEInvoiceAvailable`)
  const hasEInvoiceReference = resp?.data

  return hasEInvoiceReference ?? false
}

const _getDirectDebitPlankWall = async (config: IInvoiceSettingsPageDatahandler) => {
  const { services, translations, activeAccount, setShowDirectDebitPopup, relationship } = config
  const { GET } = services
  const { isGuest } = relationship

  let resp = await GET(`Account/${activeAccount.accountId}/avtalegiro/status`)
  let status: DirectDebitStatus = resp?.data
  let activated = status === 'Active'

  let paymentPlankWall: IMSPlankWall = {
    title: getText('paymentMethodTitle', translations),
    planks: [
      getPlankPrefab(activated ? 'Text' : 'Action', {
        action: !activated
          ? {
              text: getText('plankDirectDebitAction', translations),
              icon: IconType.ExternalLinkThick,
              onClick: () => setShowDirectDebitPopup(true),
              disabled: isGuest
            }
          : undefined,
        left: {
          title: getText('plankDirectDebitTitle', translations)
        },
        right: {
          template: MS_ButtonTemplate('Light', 'primary'),
          title: activated ? getText('plankDirectDebitValueActive', translations) : undefined,
          customization: activated
            ? {
                title: {
                  color: BrandColors['status-shade-light-1']
                }
              }
            : undefined
        },
        actionIconPlacement: 'Right'
      })
    ]
  }

  return paymentPlankWall
}