import { createString } from './../../../services/collection/HelperService'
import { IAction, IconType, IMasterPlank } from '@fjordkraft/fjordkraft.component.library'
import { getPlankPrefab, ITextPlankPrefab } from '../../../Prefabs'
import { Constants } from '../../../data'
import { format } from 'date-fns'
import {
  IAddonData,
  ICustomer,
  ICustomerInstallation,
  IDefaultProps,
  IServiceEpiPage,
  IServicePage,
  IServiceStatus
} from '../../../models'
import { getServiceStatusColor, getText, shouldServiceBeAvailable } from '../../../services'
import { IArticleItem } from '../../../components'
import { IPlankHouse } from '../../../blocks'
import { getRequest, typedGetRequest } from '../../../contexts'
import { getSteddiStartDate, getSteddiStatus } from '../ServicePage/Datahandling/UniqueServices'

// ************************************
// Public
// ************************************

export interface IServiceOverviewPageData extends IDefaultProps {
  addonStates?: IAddonData[]
}

export const getPageContent = async (config: IServiceOverviewPageData) => {
  const { translations, user } = config
  const { installation } = user

  if (translations && installation) {
    let availablePages = await _getAvailableServicesPagesWrapper(config)
    let servicePlanksAndArticles = _parseServiceData(availablePages, translations)
    return {
      ...config,
      sub: {
        title: getText('pageTitle', translations),
        back: {
          text: installation.address.streetAddress,
          link: Constants.paths.energyPage
        }
      },
      house: _getHouse(availablePages, servicePlanksAndArticles),
      availableServices: {
        title: getText('servicesTitle', translations),
        items: servicePlanksAndArticles.articles
      }
    }
  }
}

const _getHouse = (
  services: IServicePage[],
  selection: {
    articles: IArticleItem[]
    activeProducts: IMasterPlank[]
  }
) => {
  let house: IPlankHouse | undefined

  if (services?.length > 0 && selection.activeProducts?.length > 0) {
    house = {
      plankWalls: [
        {
          planks: selection.activeProducts
        }
      ]
    }
  }

  return house
}

const _getAvailableServicesPagesWrapper = (config: IServiceOverviewPageData) => {
  return getAvailableServicePages(
    config.epiChildren,
    config.services.customerServiceFeature,
    config.services.user,
    config.services.GETTYPED,
    config.user.userData,
    config.user.installation,
    config.addonStates
  )
}

export const getAvailableServicePages = async (
  epiChildren: any,
  customerServiceFeature: boolean,
  user: any,
  GETTYPED: getRequest,
  userData?: ICustomer,
  installation?: ICustomerInstallation,
  addonStates?: IAddonData[]
) => {
  let availablePages: IServicePage[] = []

  if (installation && epiChildren && userData && addonStates) {
    for (let data of addonStates) {
      let status: IServiceStatus | undefined = data.state[installation.meterId]

      if (data.page.data.servicePageId === 'forutsigbar') {
        status = await _handleSteddiStatus(data.page, installation, GETTYPED, userData)
      }

      if (status) {
        data.page.data.serviceStatus = {
          fromDate: status.fromDate,
          orderId: status.orderId,
          state: status.state,
          meterId: installation.meterId,
          productDefinitionId: data.page.data.productDefinitionId
        } as IServiceStatus

        if (shouldServiceBeAvailable(data.page.data, user, customerServiceFeature, status.state)) {
          availablePages.push(data.page.data)
        }
      }
    }
  }

  return availablePages
}

// ************************************
// Private
// ************************************

const _parseServiceData = (services: IServicePage[], translation: any) => {
  let articles: IArticleItem[] = []
  let activeProducts: IMasterPlank[] = []

  services.forEach((servicePage: IServicePage) => {
    switch (servicePage.serviceStatus?.state) {
      case 'ACTIVE':
      case 'ACTIVATING':
      case 'ORDER_IN_PROGRESS':
      case 'ACTIVE_FUTURE':
      case 'ORDER_WAITING_FOR_CUSTOMER':
      case 'ORDER_FAILED':
      case 'TERMINATING':
        activeProducts.push(_getServiceStatusPlank(servicePage, translation))
        break
      case 'TERMINATED':
      case 'ORDER_CANCELLED_BY_CUSTOMER':
      case 'INACTIVE':
        articles.push({
          label: servicePage?.pitch?.label ?? '',
          title: servicePage?.pitch?.title ?? '',
          description: servicePage.pitch.description ?? '',
          additions: servicePage.pitch.additions,
          action: {
            text: servicePage?.pitch?.action?.text ?? undefined,
            link: `${servicePage.servicePageId}`
          } as IAction,
          image: {
            src: servicePage?.pitch?.image,
            alt: `${servicePage.servicePageType} bilde`
          }
        } as IArticleItem)
        break
    }
  })

  return { articles, activeProducts }
}

const _getServiceStatusPlank = (service: IServicePage, translation: any) => {
  return getPlankPrefab('Text', {
    left: {
      title: getText('pageTitle', service),
      description: getText('activeServicePlankDesc', service)
    },
    right: {
      description: _getPlankStatusValues(service.serviceStatus, translation),
      icon: IconType.ChevronRight,
      customization: {
        description: {
          color: getServiceStatusColor(service.serviceStatus?.state),
          weight: 600
        }
      }
    },
    action: {
      link: `${service.servicePageId}`
    } as IAction
  } as ITextPlankPrefab)
}

const _handleSteddiStatus = async (
  servicePage: IServiceEpiPage,
  installation: ICustomerInstallation,
  GETTYPED: typedGetRequest,
  userData?: ICustomer
) => {
  if (userData?.accounts && installation) {
    const steddiStatus = await getSteddiStatus(userData.accounts, installation.accountId, GETTYPED)
    return {
      fromDate: steddiStatus === 'ACTIVE_FUTURE' ? getSteddiStartDate().toDateString() : undefined,
      orderId: '',
      state: steddiStatus,
      productDefinitionId: servicePage.data.productDefinitionId,
      meterId: installation?.meterId ?? ''
    } as IServiceStatus
  }
  return undefined
}

const _getPlankStatusValues = (status: IServiceStatus | undefined, translation: any): string | undefined => {
  switch (status?.state) {
    case 'ACTIVE':
      return getText('plankServiceActive', translation)
    case 'ACTIVE_FUTURE':
      if (status.fromDate)
        return createString(getText('plankServiceActiveFuture', translation), {
          date: format(new Date(status.fromDate), 'dd.MM.yyyy')
        })
      break
    case 'TERMINATED':
      return getText('plankServiceTerminated', translation)
    case 'ORDER_CANCELLED_BY_CUSTOMER':
      return getText('plankServiceCancelledByCustomer', translation)
    case 'ORDER_FAILED':
      return getText('plankServiceOrderFailed', translation)
    case 'ACTIVATING':
    case 'TERMINATING':
    case 'ORDER_IN_PROGRESS':
    case 'ORDER_WAITING_FOR_CUSTOMER':
      return getText('plankServiceOrderInProgress', translation)
  }
}