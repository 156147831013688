import { IAction, IconType, IImage, IOption, Theme } from '@fjordkraft/fjordkraft.component.library'
import { IDetailsDescriptionBlock, IHighlightedDropdownBlock, IPlankHouse, ISectionBlock } from '../../../blocks'
import {
  IAddonData,
  ICustomerAccountInformation,
  ICustomerInstallation,
  IDefaultProps,
  IServices,
  IUser,
  ServiceStatus
} from '../../../models'
import { IServiceBasePageView } from './ServiceBasePage'
import {
  createString,
  getStatus,
  getStatusWrapper,
  getText,
  parseActiveExtraProducts,
  showingDropdownOrderWrapper
} from '../../../services'
import { Constants } from '../../../data'
import {
  fetchCoreDetails,
  getServicePageDataDescriptions,
  getServicePageDataPitchCards,
  getServicePageDataSections,
  getServicePageHouseData,
  IServicePageDataDescriptions
} from './Datahandling'
import { IServiceOrderOrCancel } from '../../../modals'
import { v4 as uuid4 } from 'uuid'
import { typedGetRequest } from '../../../contexts'

export interface IServiceBasePageData extends IDefaultProps {
  onClickServiceHandling: (value: IServiceOrderOrCancel) => void
  setInstallation?: (installation?: ICustomerInstallation) => void
  account?: ICustomerAccountInformation
  addonStates?: IAddonData[]
  GETTYPED: typedGetRequest
}

// ************************************
// Main
// ************************************

export const getServiceBasePageData = async (
  config: IServiceBasePageData
): Promise<IServiceBasePageView | undefined> => {
  const translations = config.translations
  if (config?.translations) {
    return {
      ...config,
      sub: {
        title: _getTitle(config),
        back: _getBack(config)
      },
      localBrand: getLocalBrand(translations),
      banner: getBanner(translations),
      house: await _getHouse(config),
      sections: await getSectionsWrapper(config),
      pitchCards: await _getPitchCardsWrapper(config),
      coreDetails: await getCoreDetailsWrapper(config),
      descriptions: await getDescriptions(config),
      dropOrder: await getDropOrder(config)
    } as IServiceBasePageView
  }
}

export const getLocalBrand = (translations: any): string => {
  return `brand-${translations.servicePageType?.toLocaleLowerCase() ?? Constants.uiBrand}`
}

// ************************************
// PRIVATE: Descriptions
// ************************************

export const getDescriptions = async (
  config: IServiceBasePageData
): Promise<IServicePageDataDescriptions | undefined> => {
  const { translations, user } = config
  const { userData } = user

  if (translations && userData) {
    return getServicePageDataDescriptions(translations, await getStatusWrapper(config))
  }
}

// ************************************
// PRIVATE: Core Details
// ************************************

export const getCoreDetailsWrapper = async (
  config: IServiceBasePageData
): Promise<IDetailsDescriptionBlock[] | undefined> => {
  return await getCoreDetails(
    config.activeTheme,
    config.activeBrand,
    config.translations,
    config.user,
    config.services,
    config.addonStates,
    config.setInstallation
  )
}

export const getCoreDetails = async (
  activeTheme: Theme,
  activeBrand: string,
  translations: any,
  user: IUser,
  services: IServices,
  addonStates?: IAddonData[],
  setInstallation?: (installation?: ICustomerInstallation) => void
): Promise<IDetailsDescriptionBlock[] | undefined> => {
  const { userData } = user

  if (userData && translations) {
    const status = await getStatus(translations, user, services.GETTYPED, addonStates)
    const coreDetails = await fetchCoreDetails(
      translations,
      activeTheme,
      activeBrand,
      user,
      status,
      services,
      setInstallation,
      addonStates
    )
    return coreDetails
  }
}

// ************************************
// PRIVATE: Sections
// ************************************

export const getSectionsWrapper = async (config: IServiceBasePageData): Promise<ISectionBlock[] | undefined> => {
  return getSections(config.translations, config.user, config.services, config.addonStates)
}

export const getSections = async (
  translations: any,
  user: IUser,
  services: IServices,
  addonStates?: IAddonData[]
): Promise<ISectionBlock[] | undefined> => {
  const { userData } = user

  if (translations && userData) {
    return await getServicePageDataSections(
      translations,
      user,
      services,
      await getStatus(translations, user, services.GETTYPED, addonStates)
    )
  }
}

// ************************************
// PRIVATE: Pitch Cards
// ************************************

const _getPitchCardsWrapper = async (config: IServiceBasePageData) => {
  const { translations, user, services, addonStates } = config
  return await getPitchCards(translations, user, services, addonStates)
}

export const getPitchCards = async (
  translations: any,
  user: IUser,
  services: IServices,
  addonStates?: IAddonData[]
) => {
  if (translations && user.userData) {
    const serviceStatus = await getStatus(translations, user, services.GETTYPED, addonStates)
    return getServicePageDataPitchCards(translations, serviceStatus)
  }
}

// ************************************
// PRIVATE: Title
// ************************************

const _getTitle = (config: IServiceBasePageData): string => {
  return getText('pageTitle', config.translations)
}

export const getBanner = (translations: any): IImage => {
  return {
    src: translations.imageSrc,
    alt: `${getText('pageTitle', translations)}`
  } as IImage
}

// ************************************
// PRIVATE: Back
// ************************************

const _getBack = (config: IServiceBasePageData): IAction => {
  return {
    text: getText('back', config.translations),
    link: `${Constants.paths.servicesPage}`
  } as IAction
}

// ************************************
// PRIVATE: House
// ************************************

const _getHouse = async (config: IServiceBasePageData): Promise<IPlankHouse | undefined> => {
  if (config) {
    return getServicePageHouseData(config)
  }
}

// ************************************
// PRIVATE: Drop Order
// ************************************
// dropDOWN -> shows e.g. 'pulsmåler' before popup is open.

export const getDropOrder = async (config: IServiceBasePageData): Promise<IHighlightedDropdownBlock | undefined> => {
  const { translations, onClickServiceHandling, addonStates, user, relationship } = config
  const { isGuest } = relationship
  const { installation, userData } = user

  if (
    user.installation &&
    translations?.serviceAdditionalAddonBlocks &&
    translations.serviceAdditionalAddonBlocks.length > 0 &&
    translations.allowOrder
  ) {
    let status: ServiceStatus = await getStatusWrapper(config)
    let options: IOption[] = []

    if (showingDropdownOrderWrapper(config, status)) {
      const activeExtraProductIds = parseActiveExtraProducts(user.installation, config.translations, addonStates)

      for (let additionalAddonService of translations.serviceAdditionalAddonBlocks) {
        if (additionalAddonService.active) {
          const serviceAlreadyActiveOnContract = activeExtraProductIds?.includes(
            Number(additionalAddonService.serviceId)
          )

          const optionText = `${getText('name', additionalAddonService, 'translationItems')}${
            serviceAlreadyActiveOnContract
              ? '\n' + getText('disabledDueToServiceAlreadyActive', additionalAddonService, 'translationItems')
              : ''
          }`

          options.push({
            id: uuid4(),
            text: optionText,
            value: additionalAddonService.serviceId,
            disabled: serviceAlreadyActiveOnContract
          })
        }
      }

      // Sorting the disabled addons last:
      options.sort((a, b) => {
        return Number(a.disabled) - Number(b.disabled)
      })

      let activeChoice: IOption = options[0]

      return {
        title: getText('dropOrderTitle', translations),
        description: createString(getText('dropOrderSubTitle', translations), {
          address: installation?.address.streetAddress,
          meterId: installation?.meterId
        }),
        dropDownPlaceholder: getText('dropOrderPlaceholderText', translations),
        action: {
          disabled: isGuest,
          text: getText('order', translations),
          onClick: () => {
            onClickServiceHandling({
              status: status,
              userData,
              page: translations,
              installation,
              selectedSubService: activeChoice
            })
          },
          icon: IconType.ExternalLinkThick
        },
        onChange: (option: IOption) => {
          activeChoice = option
        },
        options
      }
    }
  }

  return undefined
}