import {
  BrandColors,
  IClampedDropdown,
  ITabSelectDropdownTemplate,
  TabSelectDropdownTemplate,
  Theme
} from '@fjordkraft/fjordkraft.component.library'
import { Constants } from '../../../data'

// ************************************
// Export
// ************************************

export const MS_TabSelectDropdownTemplate = (
  theme: Theme,
  tabScaling: IClampedDropdown,
  bordered: boolean,
  placement?: 'middle' | 'first' | 'last' | undefined,
  brand: string = Constants.uiBrand
) => {
  let base: ITabSelectDropdownTemplate = TabSelectDropdownTemplate(theme, tabScaling, bordered, placement)

  if (brand === 'brand-trondelagkraft') {
    if (base.button.palette?.active) {
      base.button.palette.active.extra = [
        { key: 'textBackground', value: { color: BrandColors['primary-shade-light-1'] } }
      ]
    }
  }

  return base
}