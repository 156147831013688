import { IAction } from '@fjordkraft/fjordkraft.component.library'
import { IEpiPage, IPage, ITranslationItem } from './IPage'
import { IDescriptionBlock, IPlankHouse } from '../../../blocks'
import { IDescriptionPoint } from '../../../components'

/**
 * Explanations:
 *
 * INACTIVE: Never been active.
 * ACTIVE: Currently active.
 * ACTIVE_FUTURE: The addon has a start date in the future.
 * ACTIVATING: Activation order has been completed, waiting for confirmation.
 * TERMINATED: Previously been active.
 * TERMINATING: Termination order has been completed, waiting for confirmation.
 * ORDER_IN_PROGRESS: Currently active order.
 * ORDER_FAILED: Order failed.
 * ORDER_WAITING_FOR_CUSTOMER: The userData has to answer an SMS or something similar before order is finished.
 * ORDER_CANCELLED_BY_CUSTOMER: The userData has made use of the cancellation right ("angreretten") for produktet.
 */

export const enum ServiceStatusEnum {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  ACTIVATING = 'ACTIVATING',
  ACTIVE_FUTURE = 'ACTIVE_FUTURE',
  TERMINATED = 'TERMINATED',
  TERMINATING = 'TERMINATING',
  ORDER_IN_PROGRESS = 'ORDER_IN_PROGRESS',
  ORDER_FAILED = 'ORDER_FAILED',
  ORDER_WAITING_FOR_CUSTOMER = 'ORDER_WAITING_FOR_CUSTOMER',
  ORDER_CANCELLED_BY_CUSTOMER = 'ORDER_CANCELLED_BY_CUSTOMER'
}
export type ServiceStatus = keyof typeof ServiceStatusEnum

export const enum ServiceOrderStepsEnum {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  IN_PROGRESS = 'IN_PROGRESS',
  DEFAULT = 'DEFAULT',
  LOADING = 'LOADING',
  REDIRECT_SUCCESS = 'REDIRECT_SUCCESS',
  SELECT = 'SELECT',
  ERROR = 'ERROR'
}
export type ServiceOrderSteps = keyof typeof ServiceOrderStepsEnum

export const enum ServiceTypesEnum {
  FORUTSIGBAR = 'forutsigbar',
  TRUMF = 'trumf',
  MOMENT = 'moment'
}
export type ServiceTypes = `${ServiceTypesEnum}`

export const enum OrderMethodTypeEnum {
  INSTALLATION = 'installation',
  INVOICEAGREEMENT = 'invoiceagreement',
  USERDATA = 'userData'
}
export type OrderMethodType = `${OrderMethodTypeEnum}`

export const enum OnClickServiceOrderActionEnum {
  ORDER = 'ORDER',
  CANCEL = 'CANCEL'
}
export type OnClickServiceOrderAction = keyof typeof OnClickServiceOrderActionEnum

export interface IServiceStatus {
  orderId?: string
  fromDate?: string
  state: ServiceStatus
  productDefinitionId: string
  meterId: string
}

export interface IServicePage extends IPage {
  epiContentId: number
  agreementCampaigns: [agreementCampaignItem]
  campaignId: number
  allowOrder: boolean
  servicePageShow: boolean
  smsConfirmation: boolean
  servicePageType: string
  imageItems?: any[]
  imageSrc: string
  orderMethodType: OrderMethodType
  pitch: any
  productDefinitionId: string
  serviceDescriptions: IServiceStatusDescriptions[]
  servicePageId: string
  serviceStatus?: IServiceStatus
  showForActiveCustomersOnly: boolean
  serviceAdditionalAddonBlocks?: IAdditonalAddonService[]
}

export interface IServicePitchCard {
  image: string
  title: string
  description: string
}

export interface IServiceSectionBlock {
  title: string
  subTitle: string
  description: string
  logo: string
  image: string
  imagePlacement: 'right' | 'left'
  actionText: string
  roundImage?: boolean
}

export const enum ServiceStatusDescriptionEnum {
  DEFAULT = 'default',
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
  PENDING = 'pending'
}
export type ServiceStatusDescription = `${ServiceStatusDescriptionEnum}`

export interface IServiceStatusDescriptions {
  status: ServiceStatusDescription
  infoBlocks: IDescriptionBlock[]
  pitches: IServicePitchCard[]
  sectionBlocks: IServiceSectionBlock[]
  servicePopupBlock: IServicePopupBlock
  pitchesTitle?: string
  pitchesDescription?: string
}

export interface IServicePopupCheckbox {
  useCheckbox: boolean
  alreadyChecked: boolean
  checkboxDescription: string
  id?: string
}

export interface IServicePopupBlock {
  texts: ITranslationItem[]
  dataPoints: IDescriptionPoint[]
  checkboxes?: IServicePopupCheckbox[]
  links: IAction[]
  description: string
  orderResult: ServiceOrderSteps
  orderMethodType: OrderMethodType
  addonStateResponse?: any
  house?: IPlankHouse
  selectedExtraProductDesc?: string
}

export interface IServiceEpiPage extends Omit<IEpiPage, 'data'> {
  data: IServicePage
}

export type agreementCampaignItem = {
  agreementId: number
  campaignId: number
}

export interface IDataPoint {
  amount: number
  amountSuffix: string
  key: string
  name: string
}

export interface IAdditonalAddonService {
  active: boolean
  blockId: string
  modelType: string
  serviceId: string
  translationItems: ITranslationItem[]
  dataPoints: IDataPoint[]
  textSubbed?: string
  textUnSubbed?: string
}