import { BrandColors, IComponentTransform } from '@fjordkraft/fjordkraft.component.library'

// ************************************
// Transform
// ************************************

const _transform = {
  width: 'auto',
  height: 'auto',
  outline: {
    type: 'dashed',
    thickness: 2
  },
  grid: {
    boxSizing: 'border-box',
    direction: 'row',
    alignment: 'center'
  }
} as IComponentTransform

// ************************************
// Palette
// ************************************

const _palette = {
  'brand-fjordkraft': {
    Light: {
      background: { color: BrandColors['transparent'] },
      icon: { color: BrandColors['action-shade-light-1'] },
      hover: {
        background: { color: BrandColors['transparent'] },
        icon: { color: BrandColors['action-shade-light-1'], opacity: 0.8 }
      },
      focus: {
        background: { color: BrandColors['transparent'] },
        icon: { color: BrandColors['action-shade-light-1'] },
        outline: { color: BrandColors['action-shade-light-1'] }
      },
      disabled: {
        background: { color: BrandColors['transparent'] },
        icon: { color: BrandColors['action-shade-light-1'], opacity: 0.3 }
      }
    },
    Dark: {
      background: { color: BrandColors['transparent'] },
      icon: { color: BrandColors['action-shade-light-1'] },
      hover: {
        background: { color: BrandColors['transparent'] },
        icon: { color: BrandColors['text-shade-light-1'], opacity: 0.8 }
      }
    }
  }
}

// ************************************
// Export
// ************************************

export const BUTTON_TEMPLATE_ICON = {
  themedPalette: _palette,
  transform: _transform
}