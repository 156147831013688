import React, { useEffect, useMemo, useState } from 'react'
import { IAction, IMasterPlank, IMasterPlankTemplate, MasterPlank } from '@fjordkraft/fjordkraft.component.library'
import { getPlankPrefab } from '../../Prefabs'
import { isExternalUrl } from '../../services'
import { useNavigate } from 'react-router-dom'
import { MS_MasterPlankTemplate } from '../../Prefabs/Templates/planks/MS_MasterPlankTemplate'
import { useDefaultPageContext } from '../../contexts'

export interface IStatePlank extends IMasterPlank {
  promise?: Promise<IMasterPlank | undefined>
  promiseId?: string
  stateFailed?: () => void
}

export const StatePlank = (props: IStatePlank) => {
  // ************************************
  // Properties
  // ************************************

  const { theme = 'Light', brand, location, promise, disabled, stateFailed } = props
  const { setContentLoading } = useDefaultPageContext()
  const navigate = useNavigate()

  // ************************************
  // Helpers
  // ************************************

  const _getDefaultPlank = () => {
    if (promise) {
      return getPlankPrefab('Placeholder', {
        theme,
        brand,
        disabled
      })
    } else {
      return props
    }
  }

  // ************************************
  // Lifecycle
  // ************************************

  const [plankData, setPlankData] = useState<IMasterPlank>(_getDefaultPlank())

  useEffect(() => {
    if (promise) {
      handlePromiseStates()
    } else {
      setPlankData(props)
    }
  }, [])

  // ************************************
  // Async Functionality
  // ************************************

  const handlePromiseStates = async () => {
    try {
      let resp = (await promise) as IMasterPlank

      if (resp) {
        resp.location = location

        if (resp.action?.link) {
          resp.action.useRouterLink = !isExternalUrl(resp.action.link)
        }
      } else {
        _callFailed()
      }

      setPlankData(resp)
    } catch {
      _callFailed()
    }
  }

  const _callFailed = () => {
    if (stateFailed) {
      stateFailed()
    }
  }

  // ************************************
  // Helpers
  // ************************************

  const isRouterLink = (action?: IAction) => {
    return action?.link && !isExternalUrl(action.link ?? '')
  }

  const _routerAction = (action?: IAction) => {
    if (action && isRouterLink(action)) {
      return {
        ...action,
        ...({
          onClick: e => {
            if (action?.link) {
              setContentLoading(true)
              navigate(action.link)
              e.preventDefault()
            }
          },
          overrideTagEnforcer: true,
          link: action.link
        } as IAction)
      }
    } else {
      return action
    }
  }

  const _templateHandling = (data: IMasterPlank): IMasterPlankTemplate => {
    let base: IMasterPlankTemplate = MS_MasterPlankTemplate(plankData?.theme ?? theme, plankData?.location ?? 'single')

    if (data?.template) {
      base = data.template
    }

    if (base.transform.grid && plankData?.action) {
      base.transform.grid.tagType = plankData?.action?.link ? 'a' : 'button'
    }

    return base
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderPlank = useMemo(() => {
    if (plankData) {
      return (
        <MasterPlank
          {...plankData}
          template={_templateHandling(plankData)}
          action={_routerAction(plankData?.action)}
        />
      )
    }
  }, [plankData])

  return <>{_renderPlank}</>
}