import React, { useEffect, useMemo, useState } from 'react'
import { ServiceBasePage } from './ServiceBasePage'
import {
  useApplicationAddonServicesContext,
  useApplicationCoreDataContext,
  useApplicationDefaultContext
} from '../../../contexts'
import {
  getBanner,
  getCoreDetails,
  getDescriptions,
  getDropOrder,
  getLocalBrand,
  getPitchCards,
  getSections
} from './ServiceBasePageData'
import {
  IServicePopupBlock,
  IServicePopupCheckbox,
  OnClickServiceOrderAction,
  ServiceOrderSteps,
  ServiceOrderStepsEnum
} from '../../../models'
import { capitalizeFirstLetter, getServiceStatus, getText, redirectToSteddiOrderAndCancelUrl } from '../../../services'
import { getServiceDescriptionForStatus, initialSelectOption } from '../../../modals/ServiceManagementPopup/utils'
import { useSearchParams } from 'react-router-dom'
import { getTranslations } from '../../pageUtils'
import { Constants } from '../../../data'
import { useParams } from 'react-router'
import { PopupContent } from '../../../modals/ServiceManagementPopup/components/PopupContent'
import { v4 as uuid4 } from 'uuid'
import _ from 'lodash'
import { IServiceOrderOrCancel, PopupCard } from '../../../modals'
import { getSteddiHouse } from './Datahandling/UniqueServices'

export const SteddiServicePage = () => {
  //contextdata
  const { defaultProps } = useApplicationDefaultContext()
  const {
    epiChildren,
    activeTheme: theme,
    activeBrand: brand,
    translations,
    desktopView,
    user,
    services
  } = defaultProps
  const { GET } = services
  const { addonStates, updateAddonStates } = useApplicationAddonServicesContext()
  const { setTranslation } = useApplicationCoreDataContext()

  //params
  const { serviceId } = useParams()
  const [queryParams, setQueryParams] = useSearchParams()
  const queryParamState = queryParams?.get('orderStatus') as ServiceOrderSteps | undefined

  //popupStates
  const [checkboxStates, setCheckboxStates] = useState<IServicePopupCheckbox[]>()
  const [isEditing, setIsEditing] = useState<boolean>()
  const [editingAccountData, setEditingAccountData] = useState<IServiceOrderOrCancel | undefined>(undefined)
  const classPrefix = 'service-stage-popup'

  const steddiStepsOrder = [ServiceOrderStepsEnum.DEFAULT]
  const [currentStep, setCurrentStep] = useState<ServiceOrderSteps>(queryParamState ?? steddiStepsOrder[0])

  const status = useMemo(() => {
    if (editingAccountData) return editingAccountData.status
    if (translations) return getServiceStatus(translations, addonStates, user.installation)
  }, [translations, addonStates, user.installation, editingAccountData])

  const [selectedOption, setSelectedOption] = useState<OnClickServiceOrderAction | undefined>(
    status ? initialSelectOption[status] : undefined
  )

  useEffect(() => {
    const fetchedTranslations = getTranslations(
      epiChildren,
      Constants.epiServerPageNames.services.type,
      translations,
      serviceId
    )
    setTranslation(fetchedTranslations)
  }, [defaultProps])

  useEffect(() => status && setSelectedOption(initialSelectOption[status]), [status])

  const onEditSteddi = (value: IServiceOrderOrCancel) => {
    setEditingAccountData(value)
    setIsEditing(!isEditing)
  }

  const onConfirmClickSteddi = async () => {
    setCurrentStep(ServiceOrderStepsEnum.LOADING)

    if (editingAccountData?.account && selectedOption) {
      const steddiResponse = await redirectToSteddiOrderAndCancelUrl(editingAccountData.account, selectedOption, GET)
      setCurrentStep(steddiResponse)
    }
    updateAddonStates()
  }

  const serviceActionData = useMemo(() => {
    if (status && translations) {
      const serviceStatusDescription = getServiceDescriptionForStatus(status, translations.serviceDescriptions)
      if (serviceStatusDescription?.servicePopupBlock) {
        const { servicePopupBlock } = serviceStatusDescription
        const checkboxes = servicePopupBlock?.checkboxes?.map(e => ({ ...e, id: uuid4(), alreadyChecked: false }))

        const servicePopupBlockData: IServicePopupBlock = {
          texts: servicePopupBlock.texts,
          dataPoints: [],
          selectedExtraProductDesc: '',
          description: servicePopupBlock?.description,
          links: servicePopupBlock?.links,
          checkboxes: undefined,
          orderResult: servicePopupBlock?.orderResult,
          orderMethodType: servicePopupBlock?.orderMethodType
        }

        if (!checkboxStates || !checkboxes) setCheckboxStates(checkboxes)
        return servicePopupBlockData
      }
    }
  }, [status, translations])

  const getPageData = async (config: any) => {
    const { translations, desktopView, activeTheme, relationship, user, services } = config

    return {
      sub: {
        title: getText('pageTitle', config.translations),
        back: {
          text: getText('back', config.translations),
          link: `${Constants.paths.servicesPage}`
        }
      },
      localBrand: getLocalBrand(translations), //service-brand //brukes kun til trumf, forutsigbar/steddi
      banner: getBanner(translations),
      house: await getSteddiHouse({
        user,
        services,
        translations,
        activeTheme,
        epiChildren,
        desktopView,
        onClickSteddiService: onEditSteddi,
        relationship
      }),
      sections: await getSections(translations, user, services, addonStates),
      pitchCards: await getPitchCards(translations, user, services, addonStates),
      coreDetails: await getCoreDetails(
        config.activeTheme,
        config.activeBrand,
        config.translations,
        config.user,
        config.services,
        config.addonStates,
        config.setInstallation
      ),
      dropOrder: await getDropOrder(config),
      descriptions: await getDescriptions(config)
    } as any // IServiceBasePageView
  }

  const popupTitleKey = {
    [ServiceOrderStepsEnum.FAILURE]: `failureResultTitle${capitalizeFirstLetter(selectedOption)}`,
    [ServiceOrderStepsEnum.ERROR]: `failureResultTitle${capitalizeFirstLetter(selectedOption)}`,
    [ServiceOrderStepsEnum.IN_PROGRESS]: 'waitingResultTitle',
    [ServiceOrderStepsEnum.SUCCESS]: 'successResultTitle',
    [ServiceOrderStepsEnum.REDIRECT_SUCCESS]: 'successResultTitleOrder',
    [ServiceOrderStepsEnum.SELECT]: 'confirmTitle',
    [ServiceOrderStepsEnum.DEFAULT]: `title`
  }

  const getPopupTitle = useMemo(() => {
    const textKey = _.get(popupTitleKey, `${currentStep}`, popupTitleKey[ServiceOrderStepsEnum.DEFAULT])
    return getText(textKey, serviceActionData, 'texts')
  }, [currentStep, serviceActionData])

  const navigationButtons = {
    ...(steddiStepsOrder.includes(currentStep as ServiceOrderStepsEnum) && {
      primaryAction: {
        onClick: onConfirmClickSteddi,
        text: getText('continueButton', serviceActionData, 'texts')
      },
      secondaryAction: {
        onClick: () => {
          setIsEditing(false)
          setEditingAccountData(undefined)
        },
        text: getText('closePopupButton', serviceActionData, 'texts')
      }
    })
  }

  return (
    <>
      <ServiceBasePage handleData={getPageData} />
      {serviceActionData && selectedOption && isEditing && (
        <PopupCard
          theme={theme}
          brand={brand}
          contentTitle={getPopupTitle}
          contentTitleAlignment={'align-left'}
          contentGap={4}
          onClose={() => {
            setQueryParams(undefined)
            setEditingAccountData(undefined)
            setIsEditing(false)
            updateAddonStates()
          }}
          desktopView={desktopView}
          {...navigationButtons}
        >
          <PopupContent
            currentStep={currentStep}
            classPrefix={classPrefix}
            selectedOption={selectedOption}
            isTrumf={false}
            theme={theme}
            brand={brand}
            checkboxStates={checkboxStates}
            setCheckboxStates={setCheckboxStates}
            productDefinitionId={translations.productDefinitionId}
            data={{
              dataPoints: serviceActionData?.dataPoints,
              texts: serviceActionData?.texts,
              house: serviceActionData?.house,
              selectedExtraProductDesc: serviceActionData?.selectedExtraProductDesc,
              description: serviceActionData?.description,
              links: serviceActionData?.links
            }}
            onClickSelectOption={setSelectedOption}
          />
        </PopupCard>
      )}
    </>
  )
}