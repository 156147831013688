import {
  BrandColors,
  IComponentShape,
  IComponentTemplate,
  IThemedColors,
  Theme
} from '@fjordkraft/fjordkraft.component.library'

const _transform = {
  width: '100%',
  height: '100%',
  padding: '0',
  position: {
    type: 'fixed',
    left: '0',
    top: '0'
  },
  grid: {
    direction: 'column',
    alignment: 'top-left',
    tagType: 'div',
    boxSizing: 'border-box'
  }
} as IComponentShape

const _palette = {
  Light: {
    background: { color: BrandColors['background-shade-light-1'] }
  },
  Dark: {
    background: { color: BrandColors['background-shade-dark-3'] }
  }
} as IThemedColors

export const MobileSelectMenuTemplate = (theme: Theme) => {
  return {
    transform: _transform,
    palette: _palette[theme]
  } as IComponentTemplate
}