export enum PageTypeEnum {
  BenefitPages = 'BenefitPages',
  HeadlessActiveEnergyAgreementPageType = 'HeadlessActiveEnergyAgreementPageType',
  HeadlessConsumptionCostPageType = 'HeadlessConsumptionCostPageType',
  HeadlessConsumptionUsePageType = 'HeadlessConsumptionUsePageType',
  HeadlessHomePageType = 'HeadlessHomePageType',
  HeadlessInboxPageType = 'HeadlessInboxPageType',
  HeadlessInvoicePageType = 'HeadlessInvoicePageType',
  HeadlessMovePageType = 'HeadlessMovePageType',
  HeadlessServicesPage = 'HeadlessServicesPage',
  HeadlessSpotPricePageType = 'HeadlessSpotPricePageType',
  InvoiceHistory = 'InvoiceHistory',
  SelectedInvoice = 'SelectedInvoice',
  InvoiceSettingsPage = 'InvoiceSettingsPage',
  MePage = 'MePage',
  MyCommunicationSettingsPage = 'MyCommunicationSettingsPage',
  GuestAdminPage = 'GuestAdminPage',
  PageNotFound = 'PageNotFound',
  HeadlessTutorialPageType = 'HeadlessTutorialPageType',
  AccessDeniedPage = 'AccessDeniedPage',

  HeadlessConsentPageType = 'HeadlessConsentPageType',
  ReservationPage = 'ReservationPage',
  HeadlessInvoiceEditDueDatePageType = 'HeadlessInvoiceEditDueDatePageType',
  MyResidencesPage = 'MyResidencesPage',
  HeadlessHomeNoAccessPageType = 'HeadlessHomeNoAccessPageType'
}
export type PageType = `${PageTypeEnum}`