import React, { ReactNode, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { ErrorModal } from '../modals/ErrorModal/ErrorModal'
import { Constants } from '../data'
import { User } from 'oidc-client-ts'
import { isImpersonating } from '../services'
import { AuthenticationLoadingModal } from '../modals'
import { ICustomer, IGuestRelationship } from '../models'

const checkForVippsUserNotFound = (errorMessage: string | undefined) => {
  //People trying to log in with VIPPS often don't know what values VIPPS returned to us.
  const vippsInfoRegex = /(VIPPS).*?(?<phone>\+\d+?)\/(?<birthDate>\d{4}-\d{2}-\d{2})/
  const result = vippsInfoRegex.exec(errorMessage || '')
  return result?.groups?.phone && result?.groups?.birthDate
    ? {
        phone: result.groups.phone,
        birthDate: result.groups.birthDate.split('-').reverse().join('.')
      }
    : undefined
}

export let mainUserAtStartup: ICustomer | undefined
export let chosenHostAtStartup: IGuestRelationship | undefined

export default function AuthenticationGuard(props: any) {
  // ************************************
  // Properties
  // ************************************

  const { children }: { children: ReactNode | undefined } = props
  const { isLoading, isAuthenticated, error, signinRedirect, activeNavigator, events, startSilentRenew, user } =
    useAuth()

  // ************************************
  // Lifecycle
  // ************************************

  const [triedToNavigate, setTriedToNavigate] = useState(false)

  //Renew access_token when it is about to expire.
  useEffect(() => {
    return events.addAccessTokenExpiring(() => startSilentRenew())
  }, [events, startSilentRenew])

  useEffect(() => {
    if (triedToNavigate) {
      signinRedirect({
        state: {
          redirectUrl: window.location.href
        }
      })
    }
  }, [triedToNavigate])

  // ************************************
  // Helpers
  // ************************************

  const getRelationShips = (user: User | undefined | null) => user?.profile?.relationships as string[]
  const checkAuthorized = (user: User | undefined | null) => {
    if (user?.profile?.authenticationScheme !== 'B2C') {
      //This is not B2C, no need to authorize
      return true
    }
    if (!user?.profile?.relationships) {
      return false
    }
    const brand = import.meta.env.REACT_APP_API_BRAND?.toLowerCase()
    const cutoff = new Date()
    cutoff.setMonth(cutoff.getMonth() - 3)
    const relationships = getRelationShips(user)
    return relationships.some(r => {
      const arr = r.split(';')
      return r.includes(`;${brand};`) && (!arr[4] || new Date(arr[4]) > cutoff)
    })
  }

  const loginParams = ['fkId', 'li', 'sub']
  const url = new URL(window.location.href)

  const hasLoginParamInUrl = () => {
    return loginParams.some(lp => url.searchParams.get(lp))
  }

  const handleLoginParamWhenAlreadyLoggedIn = () => {
    if (!isImpersonating(user) && user?.profile?.sub && user.profile.sub === url.searchParams.get('sub')) {
      loginParams.forEach(lp => url.searchParams.delete(lp))
      window.location.href = url.toString()
      return
    } else if (!activeNavigator && !triedToNavigate) {
      _trySignin()
    }
  }

  // ************************************
  // Render Functionality
  // ************************************

  const _renderErrorModal = (description: string, header: string = 'Ooops...') => {
    return (
      <ErrorModal
        text={header}
        description={description}
        action={{
          text: 'Prøv igjen',
          link: Constants.paths.energyPage
        }}
        brand={Constants.uiBrand}
      />
    )
  }

  const _trySignin = () => {
    setTriedToNavigate(true)
  }

  // ************************************
  // Authentication Barrier
  // ************************************

  if (isLoading || activeNavigator) {
    return (
      <AuthenticationLoadingModal
        title='Autentiserer bruker'
        subTitle='Vent litt'
        brand={Constants.uiBrand}
        theme={'Light'}
      />
    )
  }

  if (!isAuthenticated) {
    if (error) {
      window.newrelic?.addPageAction('myAccountLoginFailure', error)
      if (error.message.includes('AADB2C90157')) {
        return _renderErrorModal('Du tastet inn feil informasjon for mange ganger.')
      }
      if (error.message.includes('noUserFound')) {
        const vippsInfo = checkForVippsUserNotFound(error.message)
        if (vippsInfo) {
          return _renderErrorModal(
            `Data fra VIPPS: ${vippsInfo.phone} og fødselsdato ${vippsInfo.birthDate}. Vi fant ingen brukere med disse opplysningen i våre systemer.`,
            'Vi fant deg ikke'
          )
        }
        return _renderErrorModal('Vi fant ingen brukere med informasjonen du oppga.')
      }
      if (error.message.includes('AADB2C90273') || error.message.includes('AADB2C90091')) {
        return _renderErrorModal('Avbrøt du innloggingen?')
      }
      return _renderErrorModal(`Ser ut som noe gikk galt, error: ${error.name}`)
    } else if (!activeNavigator && !triedToNavigate) {
      _trySignin()
      return null
    } else if (!isLoading) {
      return _renderErrorModal('Ser ut som noe gikk galt med autentisering')
    }
  }

  if (hasLoginParamInUrl()) {
    handleLoginParamWhenAlreadyLoggedIn()
    return null
  }

  if (!checkAuthorized(user)) {
    return (
      <ErrorModal
        text={'Er du på rett sted?'}
        description={`Vi fant ingen aktive kundeforhold for ${import.meta.env.REACT_APP_COMPANYNAME}`}
        logOutButtonText={'Logg ut'}
        brand={Constants.uiBrand}
      />
    )
  }

  if (!isImpersonating(user)) {
    //User is authenticated. Set CALL cookie for use in the brand domain:
    //Change domain code if on top level domains with two segments (ie *.co.uk)
    const setCookie = (name: string) =>
      (document.cookie = `${name}=${new Date()
        .toLocaleString('en-US', {
          hour12: false,
          day: '2-digit',
          month: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          year: 'numeric'
        })
        .replace(',', '')}; expires=${new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * 365
      ).toUTCString()}; path=/; domain=${window.location.host.split('.').slice(-2).join('.')}; secure`)

    setCookie('CALL')
    if (getRelationShips(user).some(r => r.toLowerCase().includes(';sparkle;'))) {
      //User is also mobile user. sett CALLMOBIL cookie for use in the brand domain:
      setCookie('CALLMOBIL')
    }
  }

  // When we have a sub from the token, we check if this customer has set a chosenHost
  if (user) {
    mainUserAtStartup =
      (sessionStorage.getItem(`mainUser_${user.profile.sub}`) &&
        (JSON.parse(sessionStorage.getItem(`mainUser_${user.profile.sub}`) as string) as ICustomer)) ||
      undefined
    chosenHostAtStartup =
      (sessionStorage.getItem(`chosenHost_${user.profile.sub}`) &&
        (JSON.parse(sessionStorage.getItem(`chosenHost_${user.profile.sub}`) as string) as IGuestRelationship)) ||
      undefined
  }

  // ************************************
  // Render
  // ************************************

  return <>{isAuthenticated && children}</>
}