import {
  IAddonData,
  ICustomerAccountInformation,
  ICustomerInstallation,
  IServiceEpiPage,
  IServicePage,
  IServiceStatus,
  IUser,
  OnClickServiceOrderAction,
  OnClickServiceOrderActionEnum,
  OrderMethodTypeEnum,
  ServiceOrderSteps,
  ServiceOrderStepsEnum,
  ServiceStatus,
  ServiceStatusEnum,
  ServiceTypesEnum
} from '../../models'
import { IResponse } from '../BaseService'
import { getRequest, postRequest, typedGetRequest } from '../../contexts'
import { IServiceOrderOrCancel } from '../../modals'
import { BrandColors, CallState } from '@fjordkraft/fjordkraft.component.library'
import { isWhiteListed, logger } from './HelperService'
import { handleCreateServiceDataLayerEvent } from '../analytics/ServicesDataLayer'
import { IServiceBasePageData } from '../../pages/ServicesPagesWrapper/ServicePage/ServiceBasePageData'
import { getSteddiStatus } from '../../pages/ServicesPagesWrapper/ServicePage/Datahandling/UniqueServices'

export const fetchTrumfOrderHandling = async (
  orderAction: OnClickServiceOrderAction,
  GET: getRequest,
  POST: postRequest
): Promise<ServiceOrderSteps> => {
  if (orderAction === OnClickServiceOrderActionEnum.ORDER) {
    let resp: IResponse = await GET('Services/order/trumf')

    if (resp.callState === 'success' && resp.data) {
      window.location.href = resp.data
    }
    return ServiceOrderStepsEnum.LOADING
  } else {
    let resp: IResponse = await POST('Services/terminate/trumf')
    return resp.callState === 'success' ? ServiceOrderStepsEnum.SUCCESS : ServiceOrderStepsEnum.FAILURE
  }
}

interface IOrderService {
  meterId: string
  productDefinitionId: string
  POST: postRequest
  contentId?: number
  sendSms?: boolean
  extraProducts?: number[]
}

export const orderService = async (config: IOrderService) => {
  const { meterId, productDefinitionId, POST, contentId, sendSms = false, extraProducts } = config
  let query = `Services/order/${productDefinitionId}`
  if (sendSms) query += `&sendSms=${sendSms}`

  const response = await POST(query, {
    meterId,
    sendSms,
    campaignContentId: contentId,
    extraProducts
  })

  return { data: response.data as ServiceStatus, callState: response.callState }
}

export const cancelService = async (
  meterId: string,
  productDefinitionId: string,
  extraProducts: number[] | undefined,
  POST: postRequest
) => {
  return await POST(`Services/terminate/${productDefinitionId}`, {
    meterId,
    extraProducts
  })
}

export const redirectToSteddiOrderAndCancelUrl = async (
  account: ICustomerAccountInformation,
  orderAction: OnClickServiceOrderAction,
  GET: getRequest
): Promise<ServiceOrderStepsEnum.ERROR | ServiceOrderStepsEnum.LOADING> => {
  let type = orderAction === OnClickServiceOrderActionEnum.ORDER ? 'orderlink' : 'cancelLink'

  let resp = await GET(`Steddi/${type}/${account.accountId}`)

  if (resp.callState === 'success' && resp.data) {
    window.location.href = resp.data
    return ServiceOrderStepsEnum.LOADING
  }
  return ServiceOrderStepsEnum.ERROR
}

export const redirectToSteddiPaymentFreeUrl = async (accountNumber: string, GET: getRequest): Promise<CallState> => {
  let { callState, data } = (await GET(`Steddi/paymentfreelink/${accountNumber}`)) ?? {}
  if (callState === 'success' && data) window.location.href = data
  return callState
}

const getAddonStates = async (serviceIds: string[], GET: getRequest, refetch: boolean = false) => {
  return await GET(`Services/addonStates?addonIds=${serviceIds.toString()}`, refetch)
}

export const getParsedAddonStates = async (
  epiChildren: any,
  GET: getRequest,
  refetch: boolean = false
): Promise<IAddonData[] | undefined> => {
  let resp: IResponse = { data: undefined, callState: 'idle' }
  let addonData: IAddonData[] | undefined = undefined

  if (epiChildren) {
    let serviceEpiPages: IServiceEpiPage[] = epiChildren.HeadlessServicesPage.children

    if (serviceEpiPages?.length > 0) {
      let serviceIds: string[] = []
      addonData = []

      serviceEpiPages.forEach((servicePage: IServiceEpiPage) => {
        if (servicePage.data.productDefinitionId && servicePage.data.servicePageId !== ServiceTypesEnum.FORUTSIGBAR) {
          serviceIds.push(servicePage.data.productDefinitionId)

          if (addonData) {
            addonData.push({
              id: servicePage.data.productDefinitionId,
              page: servicePage,
              hasActiveInstallation: false,
              allInstallationsActive: false,
              state: {}
            })
          }
        } else if (servicePage.data.servicePageId === ServiceTypesEnum.FORUTSIGBAR) {
          addonData?.push({
            id: servicePage.data.servicePageId,
            page: servicePage,
            hasActiveInstallation: false,
            allInstallationsActive: false,
            state: {}
          })
        }
      })

      resp = await getAddonStates(serviceIds, GET, refetch)
      addonData = _mapAddonData(resp.data, addonData)
    }
  }
  return addonData
}

const _mapAddonData = (states?: any, addonData?: IAddonData[]): IAddonData[] | undefined => {
  let data: IAddonData[] | undefined = addonData

  if (states && addonData) {
    addonData.forEach((addon: IAddonData) => {
      if (addon.page.data.servicePageId !== ServiceTypesEnum.FORUTSIGBAR) {
        addon.state = states[addon.id]
        addon.hasActiveInstallation = _getAddonHasActive(addon)
        addon.allInstallationsActive = _getAddonAllActive(addon)
      }
    })
  }

  return data
}

const _getAddonAllActive = (addon: IAddonData): boolean => {
  return !Object.keys(addon.state).find((meterId: string) => {
    return narrowDownResultStatusForPage(addon.state[meterId]?.state) === ServiceStatusEnum.INACTIVE
  })
}

const _getAddonHasActive = (addon: IAddonData): boolean => {
  return !!Object.keys(addon.state).find((meterId: string) => {
    return addon.state[meterId]?.state === ServiceStatusEnum.ACTIVE
  })
}

// ************************************
// Handle Service Order and Cancel
// ************************************

export const handleServiceOrderAndCancel = async (
  config: IServiceOrderOrCancel,
  GET: getRequest,
  POST: postRequest
): Promise<ServiceOrderSteps> => {
  const { installation, page, addonStateResponse } = config

  if (installation) {
    // If the service is currently ACTIVE, we cancel it:
    if (config.status === ServiceStatusEnum.ACTIVE) {
      let resp = await cancelService(
        installation.meterId,
        page.productDefinitionId,
        parseActiveExtraProducts(installation, page, addonStateResponse),
        POST
      )

      if (resp.callState === 'success') {
        return ServiceOrderStepsEnum.SUCCESS
      } else {
        return ServiceOrderStepsEnum.FAILURE
      }
    }
    // Else, if the service is currently TERMINATED or INACTIVE, we order it:
    else if (
      config.status === ServiceStatusEnum.TERMINATED ||
      config.status === ServiceStatusEnum.INACTIVE ||
      config.status === ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER
    ) {
      let resp = await orderService({
        meterId: installation.meterId,
        productDefinitionId: page.productDefinitionId,
        POST,
        contentId: page.epiContentId,
        extraProducts: _parseSelectedExtraProduct(config)
      })

      if (resp.callState === 'success') {
        if (resp.data === ServiceStatusEnum.ORDER_IN_PROGRESS) {
          // Waiting 5 seconds to check if the order became active since ordering (99% of the time this is the case).
          await new Promise(r => setTimeout(r, 5000))
          let confirmResponse = await getAddonStates([page.productDefinitionId], GET, true)
          if (confirmResponse.callState === 'success') {
            let state = _tryGetStatusFromAddonResponse(
              confirmResponse.data,
              page.productDefinitionId,
              installation.meterId
            )?.state

            if (state) {
              let orderResult = mapServiceStatusToOrderResult(state)

              if (orderResult === ServiceOrderStepsEnum.SUCCESS || orderResult === ServiceOrderStepsEnum.IN_PROGRESS) {
                handleCreateServiceDataLayerEvent(config)
              }
            }
          }
          return ServiceOrderStepsEnum.IN_PROGRESS
        } else {
          return mapServiceStatusToOrderResult(resp.data)
        }
      }
      return ServiceOrderStepsEnum.FAILURE
    }
  }
  return ServiceOrderStepsEnum.DEFAULT
}

const _parseSelectedExtraProduct = (config: IServiceOrderOrCancel): number[] | undefined => {
  const { selectedSubService } = config

  if (selectedSubService && !isNaN(parseInt(selectedSubService.value))) {
    return [parseInt(selectedSubService.value)]
  }
}

// ************************************
// Handle Service Order and Cancel :: Helpers
// ************************************

export const getSpecificAddonStateForInstallation = (
  productId: string,
  meterId: string,
  addonStates?: IAddonData[]
): IServiceStatus | undefined => {
  let status: IServiceStatus | undefined = undefined

  if (addonStates && addonStates.length > 0) {
    for (let data of addonStates) {
      if (data.id === productId) {
        return data.state[meterId] as IServiceStatus
      }
    }
  }

  return status
}

const _tryGetStatusFromAddonResponse = (
  responseData: any,
  productDefinitionId: string,
  meterId: string
): IServiceStatus | void => {
  try {
    return responseData[productDefinitionId][meterId] as IServiceStatus
  } catch {
    logger(`Found no status for [${productDefinitionId}][${meterId}]`, 'warn')
  }
}

export interface IGetStatusFromInstallationOrGlobal {
  installationOnly: boolean
  translations: IServicePage
  installation?: ICustomerInstallation
  addonStates?: IAddonData[]
}

export const getStatusFromInstallationOrGlobal = (config: IGetStatusFromInstallationOrGlobal): ServiceStatus => {
  const { addonStates, translations, installationOnly, installation } = config

  if (addonStates && addonStates.length > 0) {
    for (const data of addonStates) {
      if (translations.productDefinitionId === data.id) {
        if (installation && installationOnly && data?.state) {
          return data.state[installation.meterId].state
        } else {
          return data.hasActiveInstallation ? ServiceStatusEnum.ACTIVE : ServiceStatusEnum.INACTIVE
        }
      }
    }
  }

  return ServiceStatusEnum.INACTIVE
}

const showingDropdownOrder = (translations: any, status: ServiceStatus): boolean => {
  const hasExtraServ: boolean =
    translations?.serviceAdditionalAddonBlocks && translations?.serviceAdditionalAddonBlocks?.length > 0
  const isInactive = narrowDownResultStatusForPlank(status) === ServiceStatusEnum.INACTIVE

  return hasExtraServ && isInactive
}

export const showingDropdownOrderWrapper = (config: IServiceBasePageData, status: ServiceStatus): boolean => {
  return showingDropdownOrder(config?.translations, status)
}

export const getClampedStatusFromInstallation = (
  config: IGetStatusFromInstallationOrGlobal
): ServiceStatusEnum.ACTIVE | ServiceStatusEnum.INACTIVE => {
  const { addonStates, translations, installationOnly, installation } = config

  if (!addonStates?.length) return ServiceStatusEnum.INACTIVE

  for (const data of addonStates) {
    if (translations.productDefinitionId === data.id) {
      if (installation && installationOnly) {
        return narrowDownResultStatusForPage(data.state[installation.meterId].state)
      }
      return data.hasActiveInstallation ? ServiceStatusEnum.ACTIVE : ServiceStatusEnum.INACTIVE
    }
  }

  return ServiceStatusEnum.INACTIVE
}

export const parseActiveExtraProducts = (
  installation: ICustomerInstallation,
  page: IServicePage,
  addonStateResponse: any
): number[] | undefined => {
  const extraProductIds = page.serviceAdditionalAddonBlocks?.map(addon => addon.serviceId)

  if (installation && extraProductIds && extraProductIds.length > 0) {
    const activeExtraProductIds = extraProductIds.filter(
      id =>
        getSpecificAddonStateForInstallation(id, installation?.meterId, addonStateResponse)?.state ===
        ServiceStatusEnum.ACTIVE
    )
    return activeExtraProductIds.map(id => parseInt(id))
  }
}

export const narrowDownResultStatusForPage = (
  status: ServiceStatus
): ServiceStatusEnum.INACTIVE | ServiceStatusEnum.ACTIVE => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.ACTIVE_FUTURE:
    case ServiceStatusEnum.TERMINATING:
      return ServiceStatusEnum.ACTIVE
    default:
      return ServiceStatusEnum.INACTIVE
  }
}

export const narrowDownResultStatusForPlank = (
  status: ServiceStatus
): ServiceStatusEnum.INACTIVE | ServiceStatusEnum.ACTIVE | ServiceStatusEnum.ORDER_IN_PROGRESS => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.ACTIVE_FUTURE:
      return ServiceStatusEnum.ACTIVE
    case ServiceStatusEnum.INACTIVE:
    case ServiceStatusEnum.ORDER_FAILED:
    case ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER:
      return ServiceStatusEnum.INACTIVE
    case ServiceStatusEnum.ACTIVATING:
    case ServiceStatusEnum.TERMINATING:
    case ServiceStatusEnum.ORDER_IN_PROGRESS:
    case ServiceStatusEnum.ORDER_WAITING_FOR_CUSTOMER:
      return ServiceStatusEnum.ORDER_IN_PROGRESS
    default:
      return ServiceStatusEnum.INACTIVE
  }
}

export const mapServiceStatusToOrderResult = (status: ServiceStatus): ServiceOrderSteps => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.ACTIVE_FUTURE:
    case ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER:
    case ServiceStatusEnum.ACTIVATING:
    case ServiceStatusEnum.TERMINATING:
    case ServiceStatusEnum.TERMINATED:
      return ServiceOrderStepsEnum.SUCCESS
    case ServiceStatusEnum.INACTIVE:
    case ServiceStatusEnum.ORDER_FAILED:
      return ServiceOrderStepsEnum.FAILURE
    case ServiceStatusEnum.ORDER_IN_PROGRESS:
    case ServiceStatusEnum.ORDER_WAITING_FOR_CUSTOMER:
      return ServiceOrderStepsEnum.IN_PROGRESS
    default:
      return ServiceOrderStepsEnum.DEFAULT
  }
}

export const narrowDownPopupState = (status: ServiceStatus): ServiceOrderSteps => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.ACTIVE_FUTURE:
    case ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER:
    case ServiceStatusEnum.TERMINATED:
      return ServiceOrderStepsEnum.SUCCESS
    case ServiceStatusEnum.INACTIVE:
    case ServiceStatusEnum.ORDER_FAILED:
      return ServiceOrderStepsEnum.FAILURE
    case ServiceStatusEnum.ORDER_IN_PROGRESS:
    case ServiceStatusEnum.ACTIVATING:
    case ServiceStatusEnum.TERMINATING:
    case ServiceStatusEnum.ORDER_WAITING_FOR_CUSTOMER:
      return ServiceOrderStepsEnum.IN_PROGRESS
    default:
      return ServiceOrderStepsEnum.DEFAULT
  }
}

export const getServiceStatusColor = (status: ServiceStatus | undefined) => {
  switch (status) {
    case ServiceStatusEnum.ACTIVE:
    case ServiceStatusEnum.ACTIVE_FUTURE:
      return BrandColors['status-shade-light-1']
    case ServiceStatusEnum.ACTIVATING:
    case ServiceStatusEnum.TERMINATING:
    case ServiceStatusEnum.ORDER_IN_PROGRESS:
    case ServiceStatusEnum.ORDER_WAITING_FOR_CUSTOMER:
    case ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER:
      return BrandColors['status-shade-light-2']
    case ServiceStatusEnum.ORDER_FAILED:
    case ServiceStatusEnum.TERMINATED:
    case ServiceStatusEnum.INACTIVE:
      return BrandColors['status-shade-light-3']
    default:
      return BrandColors['text-shade-light-1']
  }
}

export const shouldServiceBeAvailable = (
  page: IServicePage,
  user: any,
  loggedInAsCustomerService: boolean,
  status: ServiceStatus
) => {
  if (!page.servicePageShow) return false
  if (page.whitelistFeature) return isWhiteListed(user) // Check for whitelist feature first
  if (page.customerServiceFeature) return loggedInAsCustomerService // Check for customer service feature
  if (page.showForActiveCustomersOnly) return hasServiceActivated(status) // Check for active customers only
  return true // If none of the above, make visible for all
}

const ServiceOrderStatusBoolean: { [key in ServiceStatusEnum]: boolean } = {
  [ServiceStatusEnum.ACTIVE]: true,
  [ServiceStatusEnum.ACTIVE_FUTURE]: true,
  [ServiceStatusEnum.ORDER_IN_PROGRESS]: true,
  [ServiceStatusEnum.ORDER_WAITING_FOR_CUSTOMER]: true,
  [ServiceStatusEnum.ACTIVATING]: true,
  [ServiceStatusEnum.TERMINATING]: true,
  [ServiceStatusEnum.INACTIVE]: false,
  [ServiceStatusEnum.TERMINATED]: false,
  [ServiceStatusEnum.ORDER_FAILED]: false,
  [ServiceStatusEnum.ORDER_CANCELLED_BY_CUSTOMER]: false
}

export const hasServiceActivated = (status: ServiceStatus) => ServiceOrderStatusBoolean[status]

export const getServiceStatus = (
  translations: any,
  addonStates?: IAddonData[],
  installation?: ICustomerInstallation
) => {
  return (
    getStatusFromInstallationOrGlobal({
      installationOnly: translations.orderMethodType === OrderMethodTypeEnum.INSTALLATION,
      translations: translations,
      installation: installation,
      addonStates
    }) ?? ServiceStatusEnum.INACTIVE
  )
}

export const getSteddiServiceStatus = async (accounts: ICustomerAccountInformation[], GETTYPED: typedGetRequest) => {
  return (await getSteddiStatus(accounts, undefined, GETTYPED)) ?? ServiceStatusEnum.INACTIVE
}

export const getStatusWrapper = async (config: IServiceBasePageData) =>
  await getStatus(config.translations, config.user, config.services.GETTYPED, config.addonStates)

export const getStatus = async (
  translations: any,
  user: IUser,
  GETTYPED: typedGetRequest,
  addonStates?: IAddonData[]
): Promise<ServiceStatus> => {
  let status: ServiceStatus | undefined = undefined

  if (translations.servicePageId === ServiceTypesEnum.FORUTSIGBAR) {
    status = await getSteddiStatus(user.userData.accounts, undefined, GETTYPED)
  } else {
    status = getStatusFromInstallationOrGlobal({
      installationOnly: translations.orderMethodType === OrderMethodTypeEnum.INSTALLATION,
      translations: translations,
      installation: user.installation,
      addonStates
    })
  }
  return status ?? ServiceStatusEnum.INACTIVE
}