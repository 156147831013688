import React from 'react'
import { StyleGrid } from '@fjordkraft/fjordkraft.component.library'
import {
  useApplicationCoreDataContext,
  useApplicationUserEditWrapperContext,
  useConsentContext
} from '../../../contexts'
import { getPageContent } from './MyPageHomeData'
import { IPlankHouse, PlankHouseBlock } from '../../../blocks'
import { getText, getTextV2 } from '../../../services'
import { format } from 'date-fns'
import { ICustomerEditorInformation } from '../../../modals/CustomerInfoModal/CustomerInfoEditor/CustomerInfoEditor'
import { Constants } from '../../../data'
import { ICustomerDataToEdit } from '../../DatahandlerWrappers/ApplicationUserEditWrapper/ApplicationUserEditWrapper'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import { IDefaultViewProps, PageV2 } from '../../PageV2'
import './MyPageHome.scss'
import { IInfoGridRow, InfoGrid } from '../../../components/InfoGrid/InfoGrid'

export interface IMyPageHome extends IDefaultViewProps {
  house: IPlankHouse
  customerInformation: {
    name: string
    birthDate: Date
    customerNumber: string
    phoneNumber: string
    email: string
    homeAddress: string
  }
}

export const MyPageHome = () => {
  // ************************************
  // Properties
  // ************************************

  const classPrefix = 'invoice-setting-page'
  const { userData } = useApplicationCoreDataContext()
  const { setCustomerDataToEdit } = useApplicationUserEditWrapperContext()
  const { isGuest } = useApplicationGuestsAndHostsContext()
  const { useNewConsents } = useConsentContext()

  const getCustomerInfoRows = (config: IMyPageHome) => {
    const { translations, user } = config
    const { userData } = user
    const unknown = getText('unknown', translations)
    return [
      {
        title: getTextV2({ key: 'fieldName', translations }),
        value: `${userData?.firstName} ${userData?.lastName}`
      },
      {
        title: getTextV2({ key: 'fieldBirthdate', translations }),
        value: userData.birthDate ? format(new Date(`${userData.birthDate}`), 'dd.MM.yyyy') : unknown
      },
      {
        title: getTextV2({ key: 'fieldCustomerNumber', translations }),
        value: userData.customerId ? `${userData.customerId}` : getText('unknown', translations)
      },
      {
        title: getTextV2({ key: 'fieldPhone', translations }),
        value: userData.contactInformation?.phone ? `${userData.contactInformation?.phone}` : unknown,
        buttonRight: {
          onClick: () => setCustomerDataToEdit(_handleOnPropertyClick('phone', config)),
          title: getTextV2({ key: 'change', translations }),
          isDisabled: isGuest,
          buttonType: 'button'
        }
      },
      {
        title: getTextV2({ key: 'fieldEmail', translations }),
        value: userData.contactInformation?.email ? `${userData.contactInformation?.email}` : unknown,
        buttonRight: {
          onClick: () => setCustomerDataToEdit(_handleOnPropertyClick('email', config)),
          title: getTextV2({ key: 'change', translations }),
          isDisabled: isGuest,
          buttonType: 'button'
        }
      },
      {
        title: getTextV2({ key: 'fieldRegisteredAddress', translations }),
        value: `${
          userData.address.streetAddress ? userData.address.streetAddress : getText('unknown', translations)
        }, ${userData?.address.postalCode} ${userData?.address?.postalLocation}`,
        buttonRight: {
          title: getText('plankRegisteredAddressTooltip', translations),
          isDisabled: isGuest,
          buttonType: 'tooltip'
        }
      }
    ] as IInfoGridRow[]
  }

  // ************************************
  // Render Functionality
  // ************************************

  type CustomerProperty = 'name' | 'birthday' | 'customerNumber' | 'phone' | 'email' | 'registeredAddress'

  const _handleOnPropertyClick = (property: CustomerProperty, config: IMyPageHome) => {
    let customerEdit: ICustomerEditorInformation = {
      phoneNumber: undefined,
      email: undefined
    }
    let title: string = ''
    let topText: string = ''
    let bottomText: string = ''

    switch (property) {
      case 'phone':
        customerEdit.phoneNumber = userData?.contactInformation?.phone ?? ''
        title = getText('popupTitlePhone', config.translations)
        topText = getText('popupDescTopPhone', config.translations)
        bottomText = getText('popupDescBottomPhone', config.translations)
        break
      case 'email':
        customerEdit.email = userData?.contactInformation?.email ?? ''
        title = getText('popupTitleEmail', config.translations)
        topText = getText('popupDescTopEmail', config.translations)
        bottomText = getText('popupDescBottomEmail', config.translations)
        break
    }

    return {
      title,
      topText,
      bottomText,
      fields: customerEdit
    } as ICustomerDataToEdit
  }

  // ************************************
  // Render
  // ************************************

  return PageV2({
    setup: {
      pageType: Constants.epiServerPageNames.me.type,
      usesSubPage: true
    },
    dependencies: [{ useNewConsents }],
    handleData: getPageContent,
    render: (config: IMyPageHome) => {
      const { house, desktopView } = config

      return (
        <StyleGrid
          className={classPrefix}
          direction='column'
          alignment='top-center'
          gap={8}
        >
          <InfoGrid
            rows={getCustomerInfoRows(config)}
            view={desktopView ? 'desktop' : 'mobile'}
          />
          {house && (
            <PlankHouseBlock
              className={`${classPrefix}__house`}
              {...house}
            />
          )}
        </StyleGrid>
      )
    }
  })
}
